import SmartcpaasAppLayout from 'src/layouts/SmartcpaasAppLayout';
import { Cdr } from './charging';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------


export default function LogViewer() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: t('voice-cdr'), component: <Cdr cdrType="voice" /> },
        { id: 1, label: t('sms-cdr'), component: <Cdr cdrType="sms" /> },
    ];

    const TITLE = t('charging');

    return (
        <SmartcpaasAppLayout tabs={TABS} title={TITLE} />
    );
}
