import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { Link, NavLink } from "react-router-dom";
import menu2Fill from "@iconify/icons-eva/menu-2-fill";
import folderFill from "@iconify/icons-eva/folder-fill";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
// material
import { styled } from "@mui/material/styles";
import { Box, Stack, AppBar, Toolbar, IconButton } from "@mui/material";
// components
import { MHidden } from "../../components/@material-extend";
//
import AccountPopover from "./AccountPopover";
import LanguagePopover from "./LanguagePopover";
import { ProfileService } from "src/api/services";
import { useStore } from "src/store/Store";
import { useTranslation } from "react-i18next";
import LanguageSelector from "src/components/LanguageSelector";
// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: theme.palette.background.main,
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  backgroundColor: theme.palette.background.main,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const MenuCollapseIconStyle = styled(Icon)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

const StyledBalanceBox = styled(Box)(({ theme }) => ({
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.background.main,
  border: `2px solid ${theme.palette.secondary.main}`,
  padding: theme.spacing(0.5, 0.75),
  borderRadius: "6px",
  ...theme.typography.subtitle2,
}));

// ----------------------------------------------------------------------

AppNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function AppNavbar({ onOpenSidebar }) {
  const { t } = useTranslation();
  const [store, dispatch] = useStore();

  const updateBalance = () => {
    ProfileService.getProfile(store.user.id)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: "UPDATE_BALANCE",
            payload: {
              balance: response.data.data._balance,
            },
          });
        } else {
          throw "get user profile failed";
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /*   useEffect(() => {
    if (!(store.admin)) {
      const balanceInterval = setInterval(() => {
        updateBalance();
      }, 15000);
      return () => clearInterval(balanceInterval);
    }
  }, [])

  useEffect(() => {
    if (!(store.admin)) {
      updateBalance();
    }
  }, []) */

  return (
    <RootStyle>
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: "text.primary" }}
          >
            <MenuCollapseIconStyle icon={menu2Fill} />
          </IconButton>
        </MHidden>

        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          {store.userType === "admin" ? null : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "1vw"
              }}
            >
              <NavLink style={{ textDecoration: "none" }} to="/transactions">
                <StyledBalanceBox>{`${t("credit")} ${": $"} ${
                  store.balance ? store.balance : "0.00"
                }`}</StyledBalanceBox>
              </NavLink>
              {
                //TODO
              /* <Link to={"/shopping-cart"}>
                <ShoppingCartCheckoutIcon />
              </Link> */}
            </Box>
          )}
          {/* <LanguagePopover /> */}
          <Link to="/docs" target="_blank">
            <IconButton
              sx={{
                padding: 0,
                width: 44,
                height: 44,
                fontSize: "30px",
              }}
            >
              <Icon icon={folderFill} />
            </IconButton>
          </Link>
          <AccountPopover />
          <LanguageSelector/>
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
