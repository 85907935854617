
import api from "src/api/Api";
import urls from "src/api/Urls";

export const listServices = (params) => {
    return api.get(urls.list_number_masking_services, { params: params });
}

export const addService = (payload) => {
    return api.post(urls.list_number_masking_services, payload);
}

export const deleteService = (id) => {
    return api.delete(urls.delete_number_masking_service(id));
}

export const updateService = (id, payload) => {
    return api.put(urls.update_number_masking_service(id), payload);
}

export const listMaskingNumbers = (params) => {
    return api.get(urls.list_masking_numbers, { params: params });
}

export const addMaskingNumber = (payload) => {
    return api.post(urls.list_masking_numbers, payload);
}

export const updateMaskingNumber = (id, payload) => {
    return api.put(urls.update_masking_number(id), payload);
}

export const deleteMaskingNumber = (id) => {
    return api.delete(urls.delete_masking_number(id));
}

export const addMaskingNumberSession = (payload) => {
    return api.post(urls.list_number_masking_sessions, payload);
}

export const updateMaskingNumberSession = (id, payload) => {
    return api.put(urls.update_number_masking_session(id), payload);
}

export const deleteMaskingNumberSession = (id) => {
    return api.delete(urls.delete_number_masking_session(id));
}

export const addMaskingNumberSessionParticipants = (payload) => {
    return api.post(urls.list_number_masking_sessions_participants, payload);
}

export const updateMaskingNumberSessionParticipants = (id, payload) => {
    return api.put(urls.update_number_masking_session_participants(id), payload);
}

export const deleteMaskingNumberSessionParticipants = (id) => {
    return api.delete(urls.delete_number_masking_session_participants(id));
}

export const assignMaskingNumberToService = (payload) => {
    return api.post(urls.assign_masking_number_to_service, payload);
}