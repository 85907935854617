import SmartcpaasAppLayout from 'src/layouts/SmartcpaasAppLayout';
import {useTranslation} from 'react-i18next';
import { CustomApplication } from './applications/';
// ----------------------------------------------------------------------


export default function Accounts() {
    const {t} = useTranslation();

    const TABS = [
        {id: 0, label: t('custom-applications'), component: <CustomApplication />},
    ];
    
    const TITLE = t('applications');

    return (
        <SmartcpaasAppLayout tabs={TABS} title={TITLE}/>
    );
}
