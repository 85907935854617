import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Box, Stack, TextField, FormControl, FormLabel, Radio, RadioGroup, FormControlLabel, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { useTranslation } from 'react-i18next';
import { NumberService } from 'src/api/services';
//import { parsePhoneNumber } from 'libphonenumber-js';
// ----------------------------------------------------------------------


export default function CallForwardingForm({ formData, setModalStatus, setSnackbarStatus, setMessage, successCallback }) {
    const { t } = useTranslation();

    const validatePhoneNumber = (value) => {
        return true;
        // TODO: validate phone number using libphonenumber and country
        // try {
        //     const phoneNumber = parsePhoneNumber(value, "US");
        //     return phoneNumber.isValid();
        // } catch (error) {
        //     return false;
        // }
    };

    // TODO: validate phone number in schema
    // .test("isValidPhoneNumber", "Invalid phone number", value => validatePhoneNumber(value))
    const PartitionSchema = Yup.object().shape({
        forwardType: Yup.string(),
        voice: Yup.string().when('forwardType', {
            is: 'sms',
            then: Yup.string().nullable(true),
            otherwise: Yup.string().when('forwardType', {
                is: 'both',
                then: Yup.string().nullable(true),
                otherwise: Yup.string().nullable(true).required()
            }),
        }),
        sms: Yup.string().when('forwardType', {
            is: 'voice',
            then: Yup.string().nullable(true),
            otherwise: Yup.string().when('forwardType', {
                is: 'both',
                then: Yup.string().nullable(true),
                otherwise: Yup.string().nullable(true).required()
            }),
        }),
    });
    const formik = useFormik({
        initialValues: {
            forwardType: "both", // voice, sms, both
            voice: formData.voice_number,
            sms: formData.sms_number,
        },
        validationSchema: PartitionSchema,
        onSubmit: (values, actions) => {
            const payload = {
                voice_number: values.forwardType === "sms" ? null : (values.voice === "" ? null : values.voice),
                sms_number: values.forwardType === "voice" ? null : (values.sms === "" ? null : values.sms),
            };
            const successMessage = t('successfully-updated-call-forwarding-number-values');
            const failMessage = t('call-forwarding-number-values-could-not-be-updated');
            NumberService.updateNumber(formData.id, payload)
                .then((response) => {
                    if (response.status === 200) {
                        if (setMessage) { setMessage(successMessage); };
                        if (successCallback) { successCallback(response); };
                    } else { throw "call forwarding number operation failed"; }
                })
                .catch((err) => {
                    if (setMessage) { setMessage(failMessage); };
                })
                .finally(() => {
                    if (setSnackbarStatus) { setSnackbarStatus(true); };
                    if (setModalStatus) { setModalStatus(false); };
                    actions.setSubmitting(false);
                })
        }
    });

    const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

    const getFieldByName = (fieldName) => {
        if (fieldName === "forwardType") {
            return (
                <FormControl sx={{ ml: 1, mt: 1 }}>
                    <FormLabel sx={{textAlign: "left"}}>{t('set-rule-for')}</FormLabel>
                    <RadioGroup value={values.forwardType} onChange={(e) => { setFieldValue("forwardType", e.target.value); }} row sx={{ ml: 1.5 }}>
                        <FormControlLabel color="secondary" value="voice" control={<Radio />} label={t('common.__i18n_ally_root__.voice')} />
                        <FormControlLabel color="secondary" value="sms" control={<Radio />} label="SMS" />
                        <FormControlLabel color="secondary" value="both" control={<Radio />} label={t('both')} />
                    </RadioGroup>
                </FormControl>
            );
        }
        if (fieldName === "voice") {
            return (
                <TextField
                    fullWidth
                    disabled={values.forwardType === "sms"}
                    label={t('voice-forwarding-number')}
                    {...getFieldProps('voice')}
                    error={Boolean(touched.voice && errors.voice)}
                    helperText={touched.voice && errors.voice}
                />
            );
        }

        if (fieldName === "sms") {
            return (
                <TextField
                    fullWidth
                    disabled={values.forwardType === "voice"}
                    label={t('sms-forwarding-number')}
                    {...getFieldProps('sms')}
                    error={Boolean(touched.sms && errors.sms)}
                    helperText={touched.sms && errors.sms}
                />
            );
        }

        if (fieldName === "submitButton") {
            return (
                <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    startIcon={<SaveIcon />}
                >
                    {t('common.__i18n_ally_root__.save')}
                </LoadingButton>
            )
        }
    }

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                        {getFieldByName("forwardType")}
                        {getFieldByName("voice")}
                        {getFieldByName("sms")}
                    </Stack>
                    <br />
                    <Box sx={{ textAlign: "left", ml: 1, mb: 3 }}>
                        <Typography variant="body2" color="primary">{t('leave-blank-if-you-want-to-remove-an-existing-call-forwarding-number-value')}</Typography>
                    </Box>
                    {getFieldByName("submitButton")}
                </Form>
            </FormikProvider >
        </>
    );
}
