// material
import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';
// components
import { PaypalOptionsForm } from './Forms';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------

const StyledContainer = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.background.light,
    padding: theme.spacing(4, 4),
    margin: "1px",
    borderRadius: 10,
}));

const StyledPaypalGrid = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.background.main,
    padding: theme.spacing(4, 4),
    borderRadius: 10,
    [theme.breakpoints.up('md')]: {
        marginLeft: "-10px",
        marginRight: "10px",
    },
    [theme.breakpoints.down('md')]: {
        marginBottom: "10px",
    },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.secondary.main,
    fontWeight: "bold",
    marginBottom: "4px"
}));


// ----------------------------------------------------------------------

export default function PaymentOptions() {
    const { t } = useTranslation();
    return (
        <>
            <StyledContainer container spacing={4}>
                <StyledPaypalGrid sx={{ mr: 5 }} item xs={12} md={6}>
                    <StyledTypography variant="h4">{t('setup-paypal')}</StyledTypography>
                    <PaypalOptionsForm />
                </StyledPaypalGrid>
            </StyledContainer>
        </>
    );
}
