import { useState } from 'react';
// material
import {
    Stack,
    Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// app
import {
    AccountTypes,
    getLabelByValue
} from 'src/constants/index';
import { AccountService } from 'src/api/services';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------

export default function UpdateAccountStatusForm({ formData, setModalStatus, setSnackbarStatus, setMessage, successCallback, isEnable = false }) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const updateAccountStatus = () => {
        setLoading(true);
        let apiService, successMessage, failMessage;
        if (isEnable) {
            apiService = AccountService.enableOrganizationAccount(formData.id);
            successMessage = t('has-been-successfully-enabled', { engine: getLabelByValue(AccountTypes(), formData.account_type) });
            failMessage = t('could-not-be-enabled', { engine: getLabelByValue(AccountTypes(), formData.account_type) });
        } else {
            apiService = AccountService.disableOrganizationAccount(formData.id);
            successMessage = t('has-been-successfully-disabled', { engine: getLabelByValue(AccountTypes(), formData.account_type) });
            failMessage = t('could-not-be-disabled', { engine: getLabelByValue(AccountTypes(), formData.account_type) });
        }

        apiService
            .then((response) => {
                if (response.status === 200) {
                    setMessage(successMessage);
                    setSnackbarStatus(true);
                    setModalStatus(false);
                    setLoading(false);
                    successCallback();
                } else {
                    throw "engine could not be deleted";
                }
            })
            .catch((err) => {
                if (err.response.status === 400 && err.response.data.details === 'engine-in-use')
                    failMessage = t('could-not-be-disabled-it-has-been-assigned-to-a-service', { engine: getLabelByValue(AccountTypes(), formData.account_type) });
                setMessage(failMessage);
                setSnackbarStatus(true);
                setModalStatus(false);
                setLoading(false);
            });
    }

    return (
        <>
            <Stack spacing={3}>
                <Typography component="h6">
                    {t('are-you-sure-change-this-engine', { status: isEnable ? t('enable') : t('disable') })}
                </Typography>
                <Typography component="subtitle1" variant="h6">
                    {getLabelByValue(AccountTypes(), formData.account_type)}
                </Typography>
                <Stack sx={{ display: "block" }} direction="row" spacing={2}>
                    <LoadingButton
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={loading}
                        onClick={() => setModalStatus(false)}
                    >
                        {t('cancel')}
                    </LoadingButton>
                    <LoadingButton
                        type="submit"
                        color="secondary"
                        variant="contained"
                        loading={loading}
                        onClick={updateAccountStatus}
                    >
                        {isEnable ? t('enable') : t('disable')}
                    </LoadingButton>
                </Stack>
            </Stack>
        </>
    );
}
