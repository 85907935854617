import * as React from 'react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import {
    Stack,
    TextField,
    Autocomplete,
    Checkbox
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SendIcon from '@mui/icons-material/Send';
import SaveIcon from '@mui/icons-material/Save';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { NumberMaskingService } from 'src/api/services';
import { useTranslation } from 'react-i18next';

export default function AssignNumberForm({ formData, setModalStatus, setSnackbarStatus, setMessage, formType = "add", successCallback, isProgrammable = false }) {
    const { t } = useTranslation();
    const MaskingNumberSchema = Yup.object().shape({
        formType: Yup.string(),
        masking_number_uids: Yup.array().when('formType', {
            is: "add",
            then: Yup.array().of(Yup.string().required(t('field-is-required')).matches(/^[a-zA-Z0-9]*$/, t('masking-number-must-be-alphanumeric'))),
        }),
    });

    const formik = useFormik({
        initialValues: {
            formType: formType,
            masking_number_uids: formData.service.numbers?.map(number => number.uid) || [],
        },
        validationSchema: MaskingNumberSchema,
        onSubmit: (values, actions) => {
            let payload = {
                service_uid: formData.service.uid,
                masking_number_uids: values.masking_number_uids,
            };
            let apiService, successMessage, failMessage;
            if (formType === "add") {
                apiService = NumberMaskingService.assignMaskingNumberToService(payload);
                successMessage = t('new-masking-number-has-been-successfully-assigned');
                failMessage = t('new-masking-number-could-not-be-assigned');
            }

            apiService
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        if (setMessage) { setMessage(successMessage); };
                        if (setSnackbarStatus) { setSnackbarStatus(true); };
                        if (setModalStatus) { setModalStatus(false); };
                        if (successCallback) { successCallback(response); };
                        actions.setSubmitting(false);
                    } else { throw "assign masking number operation failed"; }
                })
                .catch((err) => {
                    if (setMessage) { setMessage(failMessage); };
                    if (setSnackbarStatus) { setSnackbarStatus(true); };
                    if (setModalStatus) { setModalStatus(false); };
                })
        },
        validateOnMount: true,
    });

    const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

    const [open, setOpen] = React.useState(false);
    const [optionsEdited, setOptionsEdited] = React.useState(false);
    const [unassignedMaskingNumbers, setUnassignedMaskingNumbers] = React.useState([]);

    React.useEffect(() => {
        if (!open) {
            fetchMaskingNumbers();
        }
    }, [open]);

    const fetchMaskingNumbers = () => {
        const params = {
            // page: paginationData.page + 1,
            page_size: 100,
            // name: filterServiceName ? filterServiceName : undefined,
            // masking_number: filterMaskingNumber ? filterMaskingNumber : undefined,
            ordering: 'numbermaskingservicephonenumber__created',
            unassigned: true,
        };
        NumberMaskingService.listMaskingNumbers(params)
            .then((response) => {
                setUnassignedMaskingNumbers([...response.data.results, ...formData.service.numbers]);
                console.log(unassignedMaskingNumbers);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const formatAutocompleteFieldValues = (values) => {
        setOptionsEdited(true);
        let selected = [];
        values.forEach(option => { selected.push(option.uid) });
        return selected;
    }

    const getFieldByName = (fieldName) => {
        const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
        const checkedIcon = <CheckBoxIcon fontSize="small" />;
        if (fieldName === "masking_number_uids") {
            return <Autocomplete
                multiple
                value={!optionsEdited ? unassignedMaskingNumbers.filter(option => formData.service.numbers.map(number => number.uid).includes(option.uid)) : unassignedMaskingNumbers.filter(option => values.masking_number_uids.includes(option.uid))}
                options={unassignedMaskingNumbers}
                disableCloseOnSelect
                disableListWrap
                getOptionLabel={(option) => option.masking_number}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option.masking_number}
                    </li>
                )}
                renderInput={(params) => (
                    <TextField {...params}
                        label={t('numbers-0')}
                        error={Boolean(touched.masking_number_uids && errors.masking_number_uids)}
                        helperText={touched.masking_number_uids && errors.masking_number_uids}
                    />
                )}
                onChange={(event, values) => setFieldValue("masking_number_uids", formatAutocompleteFieldValues(values))}
            />
        }
        if (fieldName === "submitButton" && formType !== "view") {
            return (
                <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    disabled={formType === "view" ? true : false}
                    loading={isSubmitting}
                    endIcon={formType === "test" ? <SendIcon /> : null}
                    startIcon={formType !== "test" ? <SaveIcon /> : null}
                >
                    {t('common.__i18n_ally_root__.save')}
                </LoadingButton>
            )
        }
    }

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                        {getFieldByName("masking_number_uids")}
                    </Stack>
                    <br />
                    {getFieldByName("submitButton")}
                    <br />
                </Form>
            </FormikProvider>
        </>
    );
}
