import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Container, Stack, Typography, Box, Card } from "@mui/material";
import Page from "../components/Page";
import { LoginForm } from "../components/authentication/login";
import { AdminLoginForm } from "../components/authentication/login";
import { RegisterForm } from "../components/authentication/register";
import { useTranslation } from "react-i18next";
import { ForgotPasswordForm } from "src/components/authentication/forgotPassword";
import ResetPasswordForm from "src/components/authentication/resetPassword/ResetPasswordForm";
import Logo from "src/components/Logo";
import { useStore } from "src/store/Store";
import { AcceptAdminInvitation } from "src/components/authentication/acceptAdminInvitation";
import { useTheme } from "@mui/material/styles";
import { AcceptCustomerUserInvitation } from "src/components/authentication/acceptCustomerUserInvitation";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme, backgroundimage, authType}) => ({
  display: "flex",
  alignItems: "start",
  justifyContent: "start",
  minHeight:(authType === "register" || authType === "accept-customer-user-invitation") ? "120%" : "100%",
  backgroundImage: backgroundimage ? `url(${backgroundimage})` : "none",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
}));

const ContentStyle = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(1, 2),
}));

// ----------------------------------------------------------------------

export default function Auth({ type = "login" }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [landingImage, setLandingImage] = useState(null);
  const [logo, setLogo] = useState(null);
  const [store, dispatch] = useStore();

  useEffect(() => {
    if (store.services.style.landingImage) {
      setLandingImage("data:image/png;base64," + store.services.style.landingImage);
    }
    if (store.services.style.logo) {
      setLogo(store.services.style.logo);
    }
  }, [store.manualUpdateTriggeredForPalette, store.services.style]);

  const componentDetails = (() => {
    switch (type) {
      case "login":
        return {
          title: t("login"),
          subtitle: t("sign-in"),
          link: "/register",
          navigateText: store.services.customerRegistration ? t("register-if-you-dont-have-an-account") : null,
          formComponent: <LoginForm />,
        };
      case "admin-login":
        return {
          title: t("admin-login"),
          subtitle: t("sign-in-administration"),
          link: "/admin/login",
          formComponent: <AdminLoginForm />,
        };
      case "register":
        return store.services.customerRegistration
          ? {
              title: t("register"),
              subtitle: t("register-0"),
              link: "/login",
              navigateText: t("login-if-you-already-have-an-account"),
              formComponent: <RegisterForm mainPage={true} />,
            }
          : {};
      case "forgot-password":
        return {
          title: t("forgot-password"),
          subtitle: t("forgot-password"),
          link: "/login",
          navigateText: t("login-if-you-already-have-an-account"),
          formComponent: <ForgotPasswordForm type={"customer"} />,
        };
      case "forgot-password-admin":
        return {
          title: t("forgot-password"),
          subtitle: t("forgot-password"),
          link: "/admin/login",
          navigateText: t("login-if-you-already-have-an-account"),
          formComponent: <ForgotPasswordForm type={"admin"} />,
        };
      case "new-password":
        return {
          title: t("reset-password"),
          subtitle: t("reset-password"),
          link: "/login",
          navigateText: t("login-if-you-already-have-an-account"),
          formComponent: <ResetPasswordForm type={"customer"} />,
        };
      case "new-password-admin":
        return {
          title: t("reset-password"),
          subtitle: t("reset-password"),
          link: "/login",
          navigateText: t("login-if-you-already-have-an-account"),
          formComponent: <ResetPasswordForm type={"admin"} />,
        };
      case "accept-admin-invitation":
        return {
          title: t("accept-admin-invitation"),
          subtitle: t("register"),
          formComponent: <AcceptAdminInvitation type={"admin"} />,
        };
        case "accept-customer-user-invitation":
        return {
          title: t("accept-invitation"),
          subtitle: t("register"),
          formComponent: <AcceptCustomerUserInvitation type={"admin"} />,
        };
      default:
        return {};
    }
  })();

  const { title, subtitle, link, navigateText, formComponent } = componentDetails;

  return (
    <RootStyle title={`${title}`} backgroundimage={landingImage} authType={type}>
      <Container
        className="authentication-container"
        maxWidth="xs"
        sx={{
          position: "absolute",
          top: "13%",
          left: "3%",
          [theme.breakpoints.down("sm")]: {
            top: "10%",
            left: "1%",
          },
        }}
      >
        <Card
          sx={{
            backgroundColor: theme.palette.background.light,
            padding: theme.spacing(1)
          }}
        >
          <ContentStyle>
            {logo && (
              <Box sx={{ display: "flex", flexDirection: 'column', alignItems: "center", mb: 3 }}>
                <Logo src={logo} sx={{ width: "50%", mb: 2 }} />
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 600,
                    color: theme.palette.secondary.light,
                    fontStyle: "italic",
                  }}
                >
                  {t("did-management")}
                </Typography>
              </Box>
            )}
            {subtitle && (
              <Stack sx={{  textAlign: "center" }}>
                <Typography variant="h4">{subtitle}</Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  {t("enter-your-details-below")}
                </Typography>
              </Stack>
            )}
            {formComponent}
            {navigateText && (
              <NavLink style={{ marginTop: "12px", textAlign: "center" }} to={link}>
                {navigateText}
              </NavLink>
            )}
            {(type === "login" || type === "admin-login") && (
              <NavLink
                style={{ marginTop: "12px", textAlign: "center" }}
                to= {type === "login" ? "/forgot-password" : "/admin/forgot-password"}
              >
                {t("forgotten-account")}
              </NavLink>
            )}
          </ContentStyle> 
        </Card>
      </Container>
    </RootStyle>
  );
}
