import api from "src/api/Api";
import urls from "src/api/Urls";


export const addDtmfMenu = (payload) => {
    return api.post(urls.dtmf_menu_services, payload);
}

export const listDtmfMenus = (params) => {
    return api.get(urls.dtmf_menu_services, { params: params });
}

export const updateDtmfMenu = (id, payload) => {
    return api.put(urls.dtmf_menu_services_sid(id), payload);
}

export const deleteDtmfMenu = (id) => {
    return api.delete(urls.dtmf_menu_services_sid(id));
}