import * as React from 'react';
import PropTypes from 'prop-types';
// material
import { styled } from '@mui/system';
import { Box, Typography, Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';

// ----------------------------------------------------------------------



const DialogContainer = styled(Box)(({ theme }) => ({
    p: 4,
    borderRadius: 10,
}));

const DialogAppBar = styled(AppBar)(({ theme }) => ({
    color: theme.palette.common.black,
    backgroundColor: theme.palette.secondary.main,
    fontSize: "24px",
    position: 'relative',
}));

const DialogContentStyle = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2, 2),
    textAlign: 'center',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.background.main,
    fontSize: "16px",
    flexGrow: 1,
    alignItems: 'center',
}));

const TitleStyle = styled(Typography)(({ theme }) => ({
    flexGrow: 1,
    textAlign: "center"
}));


BaseDialog.propTypes = {
    title: PropTypes.string,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    children: PropTypes.node,
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function BaseDialog({ title, open, setOpen, children, dialogProperties, closeCallback, sx }) {
    const handleClose = () => {
        setOpen(false);
        closeCallback();
    }

    return (
        <Dialog
            fullScreen={dialogProperties.fullScreen}
            scroll={dialogProperties.scroll}
            fullWidth={dialogProperties.fullWidth}
            maxWidth={dialogProperties.maxWidth}
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <DialogContainer sx={sx}>
                <DialogAppBar>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" component="h2">
                            {title}
                        </Typography>
                    </Toolbar>
                </DialogAppBar>
                <DialogContentStyle>
                    <Typography id="base-modal-content"  >
                        {children ? children : "Modal Content Component"}
                    </Typography>
                </DialogContentStyle>
            </DialogContainer>
        </Dialog>
    );
}
