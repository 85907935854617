import { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { Grid, TextField, FormControl, Stack, IconButton, Tooltip } from '@mui/material';
import EyeIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { BaseTable, TableFilterContainer } from 'src/components/table';
import SearchButton from 'src/components/buttons/SearchButton';
import AddNewButton from 'src/components/buttons/AddNewButton';
import BaseModal from 'src/components/BaseModal';
import { rowArrayToObject } from 'src/utils/Util';
import { DefaultPaginationData } from 'src/constants/index';
import { AssignNumberForm, DeleteNumberForm, NumberForm } from '../Forms';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));

export default function NumbersDialog({ formData, setModalStatus, setSnackbarStatus, setMessage, successCallback }) {
    const { t } = useTranslation();

    const TABLE_HEAD = [
        { key: "assigned_number", label: t('id') },
        { key: "name", label: t('session') },
        { key: "masking_number", label: t('masking-number') },
        { key: "action", label: t('actions') }
    ];
    
    const TABLE_FIELD_MAPPING = {
        assigned_number: { key: "id", cellComponentType: "th", index: 0 },
        name: { key: "name", index: 1 },
        masking_number: { key: "masking_number", index: 2 },
    };
    
    const [filterMaskingNumber, setFilterMaskingNumber] = useState("");
    const [filterStatus, setFilterStatus] = useState("0");
    const [openEditModal, setEditModalStatus] = useState(false);
    const [openDeleteModal, setDeleteModalStatus] = useState(false);
    const [openViewModal, setViewModalStatus] = useState(false);
    const [openAddModal, setAddModalStatus] = useState(false);
    const [openAddParticipantModal, setAddParticipantModalStatus] = useState(false);
    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [paginationData, setPaginationData] = useState(DefaultPaginationData);
    const [totalCount, setTotalCount] = useState(0);
    const [loadingData, setLoadingData] = useState(false);
    const [loading, setLoading] = useState(false);

    const loadNumbers = () => {
        if (formData.numbers !== undefined) {
            const params = {
                masking_number: filterMaskingNumber ? filterMaskingNumber : '',
            };
            const data = formData.numbers.filter(i =>
                i.masking_number.toUpperCase().includes(params.masking_number.toUpperCase()));
            setLoadingData(true);
            let items = [];
            for (const idx in data) {
                let item = new Array(TABLE_HEAD.length - 1).fill({});
                Object.entries(data[idx]).forEach(([key, value]) => {
                    if (key in TABLE_FIELD_MAPPING) {
                        item[TABLE_FIELD_MAPPING[key].index] = {
                            ...TABLE_FIELD_MAPPING[key],
                            value: value,
                        };
                    }
                });
                items.push(item);
            }
            setData(items);
            setLoadingData(false);
        }
    }

    useEffect(() => {
        loadNumbers();
        return () => {
            setData([]);
        }
    }, [paginationData, formData]);

    const formatRowData = (rowData) => {
        let formatted = [];
        rowData.map((d, idx) => {
            formatted.push(d);
        })
        return formatted;
    }

    const modalHandler = (modalType, index = undefined) => {
        if (modalType === "add") {
            setAddModalStatus(!openAddModal);
        } else if (modalType === "edit") {
            setEditModalStatus(!openEditModal);
        } else if (modalType === "delete") {
            setDeleteModalStatus(!openDeleteModal);
        } else if (modalType === "view") {
            setViewModalStatus(!openViewModal)
        } else if (modalType === "addParticipant") {
            setAddParticipantModalStatus(!openAddParticipantModal)
        }

        if (index) {
            setSelectedRow({ ...rowArrayToObject(data[index]), service: formData.uid })
        };
    }

    const getActionItems = (index) => {
        return (
            <Stack direction="row" spacing={2}>
                <Tooltip title={t('view-masking-number')}>
                    <IconButton color="secondary" size="small" aria-label="view-masking-number" onClick={() => modalHandler("view", index)}>
                        <EyeIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={t('edit-masking-number')}>
                    <IconButton color="secondary" size="small" aria-label="edit-masking-number" onClick={() => modalHandler("edit", index)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={t('delete-masking-number')}>
                    <IconButton color="secondary" size="small" aria-label="delete-masking-number" onClick={() => modalHandler("delete", index)}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Stack>
        )
    }

    return (
        <>
            <BaseModal title={t('edit-masking-number')} open={openEditModal} setOpen={setEditModalStatus} children={<NumberForm formType="edit" successCallback={successCallback} formData={selectedRow} setModalStatus={setEditModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseModal title={t('delete-masking-number')} open={openDeleteModal} setOpen={setDeleteModalStatus} children={<DeleteNumberForm successCallback={successCallback} formData={selectedRow} setModalStatus={setDeleteModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseModal title={t('view-masking-number')} open={openViewModal} setOpen={setViewModalStatus} children={<NumberForm formType="view" successCallback={successCallback} formData={selectedRow} setModalStatus={setEditModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseModal title={t('assign-new-masking-number')} open={openAddModal} setOpen={setAddModalStatus} children={<AssignNumberForm formType="add" successCallback={successCallback} formData={{ service: formData }} setModalStatus={setAddModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <TableFilterContainer>
                <Grid sx={{ alignItems: "center" }} container spacing={4}>
                    <Grid item md={9} xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                value={filterMaskingNumber}
                                label={t('masking-number')}
                                name="maskingNumber"
                                margin="normal"
                                variant="outlined"
                                color="secondary"
                                onChange={event => { setFilterMaskingNumber(event.target.value) }}
                            />
                        </FormControl>
                    </Grid>
                    <SearchButtonContainer item md={2} xs={12}>
                        <SearchButton onClick={() => { loadNumbers() }} />
                    </SearchButtonContainer>
                </Grid>
            </TableFilterContainer>
            <AddNewButton label={t('assign-new-masking-number-0')} onClick={() => modalHandler("add")} />
            <br />
            <BaseTable
                head={TABLE_HEAD}
                data={[...data].map((d, idx) => { return formatRowData(d); })}
                actionItemPrep={getActionItems}
                pagination={{ paginationData: { ...paginationData, totalCount: totalCount }, setPaginationData: setPaginationData }}
                loadingData={loadingData}
            />
        </>
    );
}
