import { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { Grid, TextField, FormControl, Stack, IconButton, Tooltip } from '@mui/material';
import EyeIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { BaseTable, TableFilterContainer } from 'src/components/table';
import SearchButton from 'src/components/buttons/SearchButton';
import AddNewButton from 'src/components/buttons/AddNewButton';
import BaseModal from 'src/components/BaseModal';
import { rowArrayToObject } from 'src/utils/Util';
import { DefaultPaginationData } from 'src/constants/index';
import { ContactForm } from './Forms';
import BaseSnackbar from 'src/components/BaseSnackbar';
import DeleteContactForm from './Forms/DeleteContactForm';
import { ContactService } from 'src/api/services';
import { useTranslation } from 'react-i18next';


const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));


export default function Contacts() {
    const { t } = useTranslation();

    const TABLE_HEAD = [
        { key: "id", label: t('id') },
        { key: "first_name", label: t('first-name') },
        { key: "last_name", label: t('last-name') },
        { key: "email", label: t('email') },
        { key: "phone_number", label: t('phone-number') },
        { key: "note", label: t('note') },
        { key: "action", label: t('actions') }
    ];


    const TABLE_FIELD_MAPPING = {
        id: { key: "id", cellComponentType: "th", index: 0 },
        first_name: { key: "first_name", index: 1 },
        last_name: { key: "last_name", index: 2 },
        email: { key: "email", index: 3 },
        phone_number: { key: "phone_number", index: 4 },
        note: { key: "note", index: 5 },
    };

    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [message, setMessage] = useState("");
    const [filterName, setFilterName] = useState("");
    const [filterSurname, setFilterSurname] = useState("");
    const [filterEmail, setFilterEmail] = useState("");
    const [filterPhoneNumber, setFilterPhoneNumber] = useState("");
    const [openEditModal, setEditModalStatus] = useState(false);
    const [openDeleteModal, setDeleteModalStatus] = useState(false);
    const [openViewModal, setViewModalStatus] = useState(false);
    const [openAddModal, setAddModalStatus] = useState(false);
    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [paginationData, setPaginationData] = useState(DefaultPaginationData);
    const [totalCount, setTotalCount] = useState(0);
    const [loadingData, setLoadingData] = useState(false);

    const fetchContacts = async () => {
        const params = {
            page: paginationData.page + 1,
            page_size: paginationData.rowsPerPage,
            first_name: filterName ? filterName : undefined,
            last_name: filterSurname ? filterSurname : undefined,
            email: filterEmail ? filterEmail : undefined,
            phone_number: filterPhoneNumber ? filterPhoneNumber : undefined,
        };
        setLoadingData(true);
        const items = [];
        try {
            const response = await ContactService.listContacts(params);
            setTotalCount(response.data.count);
            for (const idx in response.data.results) {
                let item = new Array(TABLE_HEAD.length - 1).fill({});
                Object.entries(response.data.results[idx]).forEach(([key, value]) => {
                    if (key in TABLE_FIELD_MAPPING) {
                        item[TABLE_FIELD_MAPPING[key].index] = {
                            ...TABLE_FIELD_MAPPING[key],
                            value: value,
                        };
                    }
                });
                items.push(item);
            }
            setData(items);
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setLoadingData(false);
        }
    }

    useEffect(() => {
        fetchContacts();
        return () => {
            setData([]);
        }
    }, [paginationData]);

    const formatRowData = (rowData) => {
        let formatted = [];
        rowData.map((d, idx) => {
            formatted.push(d);
        })
        return formatted;
    }

    const modalHandler = (modalType, index = undefined) => {
        if (modalType === "add") {
            setAddModalStatus(!openAddModal);
        } else if (modalType === "edit") {
            setEditModalStatus(!openEditModal);
        } else if (modalType === "delete") {
            setDeleteModalStatus(!openDeleteModal);
        } else if (modalType === "view") {
            setViewModalStatus(!openViewModal)
        }

        if (index) { setSelectedRow(rowArrayToObject(data[index])) };
    }

    const getActionItems = (index) => {
        return (
            <Stack direction="row" spacing={2}>
                <Tooltip title={t("view-contact")}>
                    <IconButton color="secondary" size="small" aria-label="view-contact" onClick={() => modalHandler("view", index)}>
                        <EyeIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={t("edit-contact")}>
                    <IconButton color="secondary" size="small" aria-label="edit-contact" onClick={() => modalHandler("edit", index)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={t("delete-contact")}>
                    <IconButton color="secondary" size="small" aria-label="delete-contact" onClick={() => modalHandler("delete", index)}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Stack>
        )
    }

    return (
        <>
            <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus} />
            <BaseModal title={t("edit-contact")} open={openEditModal} setOpen={setEditModalStatus} children={<ContactForm formType="edit" successCallback={fetchContacts} formData={selectedRow} setModalStatus={setEditModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseModal title={t("delete-contact")} open={openDeleteModal} setOpen={setDeleteModalStatus} children={<DeleteContactForm successCallback={fetchContacts} formData={selectedRow} setModalStatus={setDeleteModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseModal title={t("view-contact")} open={openViewModal} setOpen={setViewModalStatus} children={<ContactForm formType="view" successCallback={fetchContacts} formData={selectedRow} setModalStatus={setEditModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseModal title={t("add-new-contact")} open={openAddModal} setOpen={setAddModalStatus} children={<ContactForm formType="add" successCallback={fetchContacts} formData={{}} setModalStatus={setAddModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <TableFilterContainer>
                <Grid sx={{ alignItems: "center" }} container spacing={4}>
                    <Grid item md={2} xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                value={filterName}
                                label={t("first-name")}
                                name="name"
                                margin="normal"
                                variant="outlined"
                                color="secondary"
                                onChange={event => { setFilterName(event.target.value) }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={2} xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                value={filterSurname}
                                label={t("last-name")}
                                name="surname"
                                margin="normal"
                                variant="outlined"
                                color="secondary"
                                onChange={event => { setFilterSurname(event.target.value) }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                value={filterEmail}
                                label={t("email")}
                                name="email"
                                margin="normal"
                                variant="outlined"
                                color="secondary"
                                onChange={event => { setFilterEmail(event.target.value) }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                value={filterPhoneNumber}
                                label={t("phone-number")}
                                name="phonenumber"
                                margin="normal"
                                variant="outlined"
                                color="secondary"
                                onChange={event => { setFilterPhoneNumber(event.target.value) }}
                            />
                        </FormControl>
                    </Grid>
                    <SearchButtonContainer item md={2} xs={12}>
                        <SearchButton onClick={() => { fetchContacts() }} />
                    </SearchButtonContainer>
                </Grid>
            </TableFilterContainer>
            <AddNewButton label={t("add-new-contact")} onClick={() => modalHandler("add")} />
            <br />
            <BaseTable
                head={TABLE_HEAD}
                data={[...data].map((d, idx) => { return formatRowData(d); })}
                actionItemPrep={getActionItems}
                pagination={{ paginationData: { ...paginationData, totalCount: totalCount }, setPaginationData: setPaginationData }}
                loadingData={loadingData}
            />
        </>
    );
}
