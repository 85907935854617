import SmartcpaasAppLayout from 'src/layouts/SmartcpaasAppLayout';
import { Cdr, Sdr, VoiceCdrMapping } from '../logViewer';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------


export default function LogViewer() {
    const { t } = useTranslation();
    
    const TABS = [
        { id: 0, label: t('voice-cdr'), component: <Cdr cdrType="voice" /> },
        { id: 1, label: t('sms-cdr'), component: <Cdr cdrType="sms" /> },
        { id: 2, label: t('sms-api-logs'), component: <Sdr sdrType="programmablesms" /> },
        { id: 3, label: t('2fa-logs'), component: <Sdr sdrType="twofa" /> },
        { id: 4, label: t('campaign-logs'), component: <Sdr sdrType="campaign" /> },
        { id: 5, label: t('voice-cdr-mapping'), component: <VoiceCdrMapping /> },
    ];
    
    const TITLE = t('log-viewer-0');
    
    return (
        <SmartcpaasAppLayout tabs={TABS} title={TITLE} />
    );
}
