import api from "src/api/Api";
import urls from "src/api/Urls";


export const listOrganizationAccounts = (id, params) => {
    return api.get(urls.list_organization_accounts(id), { params: params });
}

export const getOrganizationAccountsReport = (id) => {
    return api.get(urls.get_organization_accounts_report(id));
}

export const addOrganizationAccount = (id, payload) => {
    return api.post(urls.add_organization_account(id), payload);
}

export const deleteOrganizationAccount = (id) => {
    return api.delete(urls.delete_organization_account(id));
}

export const updateOrganizationAccount = (id, payload) => {
    return api.put(urls.update_organization_account(id), payload);
}

export const getOrganizationAccountCredentials = (id) => {
    return api.get(urls.get_organization_account_credentials(id));
}

export const disableOrganizationAccount = (id) => {
    return api.post(urls.disable_organization_account(id));
}

export const enableOrganizationAccount = (id) => {
    return api.post(urls.enable_organization_account(id));
}

export const listPartitions = () => {
    return api.get(urls.list_partitions);
}
