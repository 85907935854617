import * as React from 'react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import {
    Stack,
    TextField,
    Select,
    Grid,
    MenuItem,
    Checkbox,
    FormControl,
    InputLabel,
    FormControlLabel
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import { LoadingButton } from '@mui/lab';
import { CampaignManagementService } from 'src/api/services';
import { useTranslation } from 'react-i18next';


const infoContainer = {
    backgroundColor: "#D5DDB9",
    color: "#0A1845",
    borderRadius: "4px",
    marginTop: "30px",
    padding: "20px 30px",
    fontSize: "12px",
    alignItems: "center",
}

export default function CampaignForm({ formData, setModalStatus, setSnackbarStatus, setMessage, successCallback }) {
    const { t } = useTranslation();

    const CampaignSchema = Yup.object().shape({
        scheduledDate: Yup.date().nullable(true),
        isRecurring: Yup.bool(),
        recurringDurationInDays: Yup.number().nullable(true),
        recurringFrequencyInMinutes: Yup.number().nullable(true),
    });

    const formik = useFormik({
        initialValues: {
            scheduledDate: null,
            isRecurring: false,
            recurringDurationInDays: 1,
            recurringFrequencyInMinutes: 60,
        },
        validationSchema: CampaignSchema,
        onSubmit: (values, actions) => {
            let payload = {
                scheduled_at: values.scheduledDate === null ? new Date() : values.scheduledDate,
                is_recurring: values.isRecurring,
                duration_in_days: values.recurringDurationInDays,
                frequency_in_minutes: values.recurringFrequencyInMinutes,
            };

            const apiService = CampaignManagementService.cloneCampaign(formData.id, payload);
            const successMessage = t('has-been-successfully-cloned', {name: formData.name});
            const failMessage = t('could-not-be-cloned', {name: formData.name});

            apiService
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        if (setMessage) { setMessage(successMessage); };
                        if (setSnackbarStatus) { setSnackbarStatus(true); };
                        if (setModalStatus) { setModalStatus(false); };
                        if (successCallback) { successCallback(response); };
                        actions.setSubmitting(false);
                    } else { throw "campaign operation failed"; }
                })
                .catch((err) => {
                    if (setMessage) { setMessage(failMessage); };
                    if (setSnackbarStatus) { setSnackbarStatus(true); };
                    if (setModalStatus) { setModalStatus(false); };
                })
        },
        validateOnMount: true,
    });

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={3} sx={{ mt: 5 }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                                label={t('scheduled-date')}
                                {...getFieldProps('scheduledDate')}
                                onChange={(newValue) => {
                                    formik.setFieldValue("scheduledDate", newValue);
                                }}
                                renderInput={(params) => <TextField {...params} helperText={t('leave-blank-to-schedule-immediately')} />}
                            />
                        </LocalizationProvider>
                        <FormControlLabel control={<Checkbox {...getFieldProps('isRecurring')} />} label={t('recurring-campaign')} />
                        {
                            formik.values.isRecurring &&
                            (<>
                                <FormControl fullWidth>
                                    <InputLabel id="duration-select-label">{t('recurring-duration-0')}</InputLabel>
                                    <Select
                                        labelId="duration-select-label"
                                        {...getFieldProps('recurringDurationInDays')}
                                        error={Boolean(touched.recurringDurationInDays && errors.recurringDurationInDays)}
                                        helperText={touched.recurringDurationInDays && errors.recurringDurationInDays}
                                        label={t('recurring-duration-0')}
                                    >
                                        <MenuItem value={1}>{t('1-day')}</MenuItem>
                                        <MenuItem value={3}>{t('3-days')}</MenuItem>
                                        <MenuItem value={7}>{t('1-week')}</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel id="frequency-select-label">{t('recurring-frequency-0')}</InputLabel>
                                    <Select
                                        labelId="frequency-select-label"
                                        {...getFieldProps('recurringFrequencyInMinutes')}
                                        error={Boolean(touched.recurringFrequencyInMinutes && errors.recurringFrequencyInMinutes)}
                                        helperText={touched.recurringFrequencyInMinutes && errors.recurringFrequencyInMinutes}
                                        label={t('recurring-frequency-0')}
                                    >
                                        <MenuItem value={60}>{t('1-hour')}</MenuItem>
                                        <MenuItem value={360}>{t('6-hours')}</MenuItem>
                                        <MenuItem value={720}>{t('12-hours')}</MenuItem>
                                    </Select>
                                </FormControl>
                            </>)
                        }
                        <Grid style={infoContainer}>
                            <h2>
                                <NotificationsIcon /> {t('scheduling-the-campaign')}
                            </h2>
                            <p style={{ height: "100" }}>
                                {t('choosing-a-date-and-time-at-which-the-campaign-has-to-take-place-0')} <b>Note:</b> {t('to-schedule-the-campaign-immediately-leave-0')} <b>{t('schedule-date')}</b> {t('as-blank-and-click-on')}
                                <b>"Save"</b>.
                            </p>
                        </Grid>
                        <LoadingButton
                            size="large"
                            type="submit"
                            variant="contained"
                            loading={isSubmitting}
                        >
                            {t('common.__i18n_ally_root__.save')}
                        </LoadingButton>
                    </Stack>
                </Form>
            </FormikProvider>
        </>
    );
}
