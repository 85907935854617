import { useEffect, useState } from 'react';
import { Grid, TextField, FormControl, Stack, IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import { DefaultPaginationData } from 'src/constants/index';
import { rowArrayToObject } from 'src/utils/Util';
import { BaseTable, TableFilterContainer } from "src/components/table";
import BaseSnackbar from "src/components/BaseSnackbar";
import BaseModal from 'src/components/BaseModal';
import SearchButton from 'src/components/buttons/SearchButton';
import { DtmfMenuForm, DeleteDtmfMenuForm } from './Forms';
import { DtmfMenuService, CampaignManagementService } from 'src/api/services';
import { useTranslation } from 'react-i18next';
import AddNewButton from 'src/components/buttons/AddNewButton';


const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));


export default function DtmfMenu() {
    const { t } = useTranslation();

    const TABLE_HEAD = [
        { key: "id", label: t("id") },
        { key: "name", label: t('name') },
        { key: "action", label: t('actions') },
    ];

    const TABLE_FIELD_MAPPING = {
        id: { key: "id", cellComponentType: "th", index: 0 },
        name: { key: "name", index: 1 },
        configuration: { key: "configuration", index: 2 },
    };

    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [message, setMessage] = useState("");
    const [openDtmfMenuModal, setOpenDtmfMenuModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openEditDtmfMenuModal, setOpenEditDtmfMenuModal] = useState(false);
    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [paginationData, setPaginationData] = useState(DefaultPaginationData);
    const [totalCount, setTotalCount] = useState(0);
    const [loadingData, setLoadingData] = useState(false);
    const [filterName, setName] = useState("");
    const [announcementData, setAnnouncementData] = useState([]);

    const fetchMenus = () => {
        const params = {
            search: filterName ? filterName : undefined,
            page: paginationData.page + 1,
            page_size: paginationData.rowsPerPage,
        };
        setLoadingData(true);
        DtmfMenuService.listDtmfMenus(params)
            .then((response) => {
                let items = [];
                setTotalCount(response.data.count);
                for (const idx in response.data.results) {
                    let item = new Array(TABLE_FIELD_MAPPING.length).fill({});
                    Object.entries(response.data.results[idx]).forEach(([key, value]) => {
                        if (key in TABLE_FIELD_MAPPING) {
                            item[TABLE_FIELD_MAPPING[key].index] = {
                                ...TABLE_FIELD_MAPPING[key],
                                value: value,
                            };
                        }
                    });
                    items.push(item);
                }
                setData(items);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoadingData(false);
            })

            CampaignManagementService.listAnnouncements({})
            .then((response) => {
                setAnnouncementData(response.data.results);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        fetchMenus();
        return () => {
            setData([]);
        }
    }, [paginationData]);

    const formatRowData = (rowData) => {
        let formatted = [];
        rowData.map((d, idx) => {
            if (d.key === "in_use") {
                formatted.push({
                    ...d,
                    value: d.value ? t('yes') : t('no'),
                });
            } else if (d.key === 'configuration') {
            }
            else {
                formatted.push(d);
            }
        })
        return formatted;
    }

    const modalHandler = (modalType, index = undefined) => {
        if (modalType === "add") {
            setOpenDtmfMenuModal(!openDtmfMenuModal);
        } else if (modalType === "edit") {
            setOpenEditDtmfMenuModal(!openEditDtmfMenuModal);
        } else if (modalType === "delete") {
            setOpenDeleteModal(!openDeleteModal);
        }
        if (index) { 
            setSelectedRow(rowArrayToObject(data[index])) 
        };
    }

    const getActionItems = (index) => {
        return (
            <Stack direction="row" spacing={2}>
                <Tooltip title={t('edit-dtmf-menu')}>
                    <IconButton color="secondary" size="small" aria-label="edit-dtmf-menu" onClick={() => modalHandler("edit", index)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={t('delete-dtmf-menu')}>
                    <IconButton color="secondary" size="small" aria-label="delete-dtmf-menu" onClick={() => modalHandler("delete", index)}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Stack>
        )
    }

    return (
        <>
            <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus} />
            <BaseModal title={t('dtmf-menu')} open={openEditDtmfMenuModal} setOpen={setOpenEditDtmfMenuModal} children={<DtmfMenuForm successCallback={fetchMenus} formData={selectedRow} announcementData={announcementData} setModalStatus={setOpenEditDtmfMenuModal} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} sx={{ width: 600 }} />
            <BaseModal title={t('dtmf-menu')} open={openDtmfMenuModal} setOpen={setOpenDtmfMenuModal} children={<DtmfMenuForm announcementData={announcementData} successCallback={fetchMenus} setModalStatus={setOpenDtmfMenuModal} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} sx={{ width: 600 }} />
            <BaseModal title={t('delete-dtmf-menu-0')} open={openDeleteModal} setOpen={setOpenDeleteModal} children={<DeleteDtmfMenuForm successCallback={fetchMenus} formData={selectedRow} setModalStatus={setOpenDeleteModal} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <TableFilterContainer>
                <Grid sx={{ alignItems: "center" }} container spacing={4}>
                    <Grid item md={3} xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                value={filterName}
                                label={t('name')}
                                name="name"
                                margin="normal"
                                variant="outlined"
                                color="secondary"
                                onChange={event => { setName(event.target.value) }}
                            />
                        </FormControl>
                    </Grid>
                    <SearchButtonContainer item md={2} xs={12}>
                        <SearchButton onClick={() => { fetchMenus() }} />
                    </SearchButtonContainer>
                </Grid>
            </TableFilterContainer>
            <AddNewButton label={t('dtmf-menu.add-menu')} onClick={() => modalHandler("add")} />
            <br />
            <BaseTable
                head={TABLE_HEAD}
                data={[...data].map((d, idx) => { return formatRowData(d); })}
                actionItemPrep={getActionItems}
                pagination={{ paginationData: { ...paginationData, totalCount: totalCount }, setPaginationData: setPaginationData }}
                loadingData={loadingData}
            />
        </>
    );
}
