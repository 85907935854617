import api from "src/api/Api";
import urls from "src/api/Urls";


export const listContacts = (params) => {
    return api.get(urls.list_add_contacts, { params: params });
}

export const addContact = (payload) => {
    return api.post(urls.list_add_contacts, payload);
}

export const getContact = (id) => {
    return api.get(urls.get_update_delete_contact(id));
}

export const updateContact = (id, payload) => {
    return api.patch(urls.get_update_delete_contact(id), payload);
}

export const deleteContact = (id) => {
    return api.delete(urls.get_update_delete_contact(id));
}

export const listContactGroups = (params) => {
    return api.get(urls.list_add_contact_groups, { params: params });
}

export const addContactGroup = (payload) => {
    return api.post(urls.list_add_contact_groups, payload);
}

export const getContactGroup = (id) => {
    return api.get(urls.get_update_delete_contact_group(id));
}

export const updateContactGroup = (id, payload) => {
    return api.patch(urls.get_update_delete_contact_group(id), payload);
}

export const deleteContactGroup = (id) => {
    return api.delete(urls.get_update_delete_contact_group(id));
}

export const assignContactToGroup = (payload) => {
    return api.post(urls.assign_contact, payload);
}
