import SmartcpaasAppLayout from 'src/layouts/SmartcpaasAppLayout';
import { Contacts, ContactGroups } from './contacts';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------

export default function ContactManagement() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: t('contacts'), component: <Contacts /> },
        { id: 1, label: t('contact-groups'), component: <ContactGroups /> },
    ];

    const TITLE = t('contact-management');

    return (
        <SmartcpaasAppLayout tabs={TABS} title={TITLE} />
    );
}
