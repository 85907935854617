import {
    Box,
    Typography,
    InputLabel,
} from '@mui/material';
import { styled } from '@mui/system';
import { fDateTime } from 'src/utils/formatTime';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------


const StyledText = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(1),
    ...theme.typography.body1,
}));


export default function DetailModal({ cdrType, data }) {
    const { t } = useTranslation();

    const getFields = () => {
        return (
            <Box sx={{ textAlign: "left" }}>
                {cdrType === "voice" ?
                    <>
                        <InputLabel>{t('account')}</InputLabel>
                        <StyledText>{data.account}</StyledText>
                        <InputLabel>{t('call-sid')}</InputLabel>
                        <StyledText>{data.callSid}</StyledText>
                        <InputLabel>{t('common.fromNumber')}</InputLabel>
                        <StyledText>{data.fromNumber}</StyledText>
                        <InputLabel>{t('common.__i18n_ally_root__.toNumber')}</InputLabel>
                        <StyledText>{data.toNumber}</StyledText>
                        <InputLabel>{t('direction')}</InputLabel>
                        <StyledText>{data.direction}</StyledText>
                        <InputLabel>{t('common.duration')}</InputLabel>
                        <StyledText>{data.duration}</StyledText>
                        <InputLabel>{t('trunk')}</InputLabel>
                        <StyledText>{data.trunk}</StyledText>
                        <InputLabel>{t('trunk-ip')}</InputLabel>
                        <StyledText>{data.ip}</StyledText>
                        <InputLabel>{t('sip-status')}</InputLabel>
                        <StyledText>{data.sipCode}</StyledText>
                        <InputLabel>{t('created-at')}</InputLabel>
                        <StyledText>{fDateTime(+data.createdDate * 1000)}</StyledText>
                    </>
                    :
                    <>
                        <InputLabel>{t('account-0')}</InputLabel>
                        <StyledText>{data.account}</StyledText>
                        <InputLabel>{t('common.fromNumber')}</InputLabel>
                        <StyledText>{data.fromNumber}</StyledText>
                        <InputLabel>{t('common.__i18n_ally_root__.toNumber')}</InputLabel>
                        <StyledText>{data.toNumber}</StyledText>
                        <InputLabel>{t('created-at')}</InputLabel>
                        <StyledText>{fDateTime(+data.createdDate * 1000)}</StyledText>
                    </>
                }
            </Box>
        )
    }

    return getFields();
}
