import SmartcpaasAppLayout from 'src/layouts/SmartcpaasAppLayout';
import Transactions from './transaction/Transactions';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------


export default function Transaction() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: t('transactions'), component: <Transactions /> },
    ];

    const TITLE = t('transactions');

    return (
        <SmartcpaasAppLayout tabs={TABS} title={TITLE} />
    );
}
