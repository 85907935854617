import SmartcpaasAppLayout from 'src/layouts/SmartcpaasAppLayout';
import { Services, Numbers } from './numberMasking/'
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------


export default function NumberMasking() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: t('service'), component: <Services /> },
        { id: 1, label: t('numbers-0'), component: <Numbers /> },
    ];
    
    const TITLE = t('number-masking');
    
    return (
        <SmartcpaasAppLayout tabs={TABS} title={TITLE} />
    );
}
