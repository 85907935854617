import { useTranslation } from "react-i18next";
import { createContext, useReducer, useContext } from "react";
import palette from "src/theme/palette";
import Reducer from "./Reducer";

const admin = JSON.parse(localStorage.getItem("admin"))
const portal = JSON.parse(localStorage.getItem("portal"))
const language = localStorage.getItem("language");
const supported_languages = JSON.parse(
  localStorage.getItem("supported_languages")
);
const services = JSON.parse(localStorage.getItem("services"));
const StoreContext = createContext(null);


const Store = ({ children, initialStateData = {} }) => {
  const { t } = useTranslation();
  const initialState = {
    admin: admin ? admin : null,
    portal: portal ? portal : null,
    services: services ? services : null,

    serviceID: null,
    customerID: null,
    packageID: null,
    cpaasGotIt: false,
    outboundGotIt: false,
    errorMessage: null,
    userType: null,
    palette:{ ...palette },
    supported_languages: supported_languages ? supported_languages : [],
    language: language || "tr-TR",
    styleUpdatedOnState: false,
    ...initialStateData,
/*     palettes: services.palettes
      ? services.palettes
      : { default: { ...palette, label: t("default") } }, */
  };

  const [store, dispatch] = useReducer(Reducer, initialState);
  if (
    initialState.styleUpdatedOnState &&
    !store.manualUpdateTriggeredForPalette
  ) {
    dispatch({
      type: "UPDATE_INITIAL_PALETTE_LOGO",
      payload: {
        services: {
          style : {
            palettes: initialStateData.palettes,
            selectedPalette: initialStateData.selectedPalette,
            logo: initialState.logo,
            landingImage: initialState.landingImage,
          },
          serviceGroups: initialStateData.serviceGroups,
          portalServiceGroups: initialStateData.portalServiceGroups,
          creditControl: initialStateData.creditControl,
          customerRegistration: initialStateData.customerRegistration,

        },
        palette: initialStateData.palettes[initialStateData.selectedPalette],
        manualUpdateTriggeredForPalette: true,
        userType: initialStateData.userType
      },
    });
  }
  return (
    <StoreContext.Provider value={[store, dispatch]}>
      {children}
    </StoreContext.Provider>
  );
};

export const useStore = () => useContext(StoreContext);
export default Store;
