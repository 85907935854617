import { format, formatDistanceToNow } from 'date-fns';
import i18n from 'src/i18n';
import { enUS, arSA, de, es, fr, hi, id, it, ja, pt, tr, zhCN, da, sv, fi  } from 'date-fns/locale';
export const API_DATETIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ssX";
// ----------------------------------------------------------------------

const LANG_LOCALE_MAP = {
  "en-US": enUS,
  "de-DE": de,
  "fr-FR": fr,
  "it-IT": it,
  "tr-TR": tr,
  "ar-AR": arSA,
  "es-ES": es,
  "hi-HI": hi,
  "id-ID": id,
  "ja-JA": ja,
  "pt-PT": pt,
  "zh-ZH": zhCN,
  "da-DA": da,
  "sv-SV": sv,
  "fi-FI": fi,
};

const getLocale = () => {
  return LANG_LOCALE_MAP[i18n.language] || enUS;
}

export function fDate(date) {
  return format(new Date(date), 'yyyy-MM-dd', {locale: getLocale()});
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm', {locale: getLocale()});
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p', {locale: getLocale()});
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}
