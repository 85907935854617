import SmartcpaasAppLayout from 'src/layouts/SmartcpaasAppLayout';
import { Report, Service, Test } from '../twoFactorAuthentication';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------


export default function TwoFactorAuthentication() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: t('service'), component: <Service /> },
        { id: 1, label: t('report'), component: <Report /> },
        { id: 2, label: t('test'), component: <Test /> },
    ];

    const TITLE = t('two-factor-authentication');
    
    return (
        <SmartcpaasAppLayout tabs={TABS} title={TITLE} />
    );
}
