import { useState, useEffect } from "react";
// store
import Store, { useStore } from "./store/Store";
// routes
import Router from "./routes";
// theme
import ThemeConfig from "./theme";
// components
import ScrollToTop from "./components/ScrollToTop";
// axios
import api from "./api/Api";
import { SiteConfigService } from "./api/services";
// utils
import { setFavIcon } from "src/utils/Util";
import { ErrorBoundary } from "react-error-boundary";
import WentWrong from "./pages/WentWrong";
import { useLocation } from "react-router-dom";
import AdminRouter from "./AdminRouter";
import PortalRouter from "./PortalRouter";

// ----------------------------------------------------------------------

const getApiUrl = () => {
  var request = new XMLHttpRequest();

  request.open("GET", document.location.origin, false);
  request.send();

  if (request.readyState === 4)
    if (
      request.getResponseHeader("X-Api-Address") === null ||
      request.getResponseHeader("X-Api-Address") === undefined
    ) {
      let islocal =
        document.location.origin.includes("127.0.0.1") ||
        document.location.origin.includes("localhost");
      return islocal ? "http://127.0.0.1:8000/api" : document.location.origin;
    } else {
      return request.getResponseHeader("X-Api-Address");
    }
};

const baseUrl = getApiUrl();
api.defaults.baseURL = baseUrl;

function ErrorFallback({ error }) {
  window.location.href = "/oops";
}

export default function App() {
  const [stateData, setStateData] = useState();
  const [router, setRouter] = useState([]);
  const location = useLocation();

  useEffect(() => {

    let isAdmin = location.pathname.includes("/admin");
    
    const fetchStyle = async () => {
      const styleResponse = await SiteConfigService.getStyle();
      if (styleResponse.status === 200) {
        const initialStateData = {
          selectedPalette: styleResponse.data.style.selectedPalette,
          palettes: styleResponse.data.style.palettes,
          logo: styleResponse.data.logo,
          landingImage:
            styleResponse.data.landing_image ?? styleResponse.data.logo,
          serviceGroups: Object.values(
            styleResponse.data.access_management
          ).map((group) => group.id),
          portalServiceGroups: Object.values(
            styleResponse.data.access_management
          )
            .filter((group) => group.enabled)
            .map((group) => group.id),
          supported_languages: styleResponse.data.supported_languages,
          /* language: localStorage.getItem("language") || "en-US", */
          styleUpdatedOnState: true,
          creditControl: true,
          customerRegistration: styleResponse.data.customer_registration,
          userType: isAdmin ? "admin" : "portal"
        };
        setStateData(initialStateData);
        setFavIcon(styleResponse.data.favicon);
      }
    };

    isAdmin ? setRouter(<AdminRouter/>) : setRouter(<PortalRouter/>) 

    fetchStyle().catch((err) => {
      console.log(err);
    });
  }, []);


  useEffect(() => {


/*     // implemment control memchanism later
    dispatch({ type: "UPDATE_USER_TYPE", payload: { userType: "admin" } }); */

  }, [])

  return (
    <Store initialStateData={stateData}>
      <ThemeConfig>
        <ScrollToTop />
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => setStateData(null)}
        >
          {router}
        </ErrorBoundary>
      </ThemeConfig>
    </Store>
  );
}
