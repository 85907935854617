import { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { Grid, TextField, FormControl, Stack, IconButton, Tooltip } from '@mui/material';
import EyeIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { BaseTable, TableFilterContainer } from 'src/components/table';
import SearchButton from 'src/components/buttons/SearchButton';
import AddNewButton from 'src/components/buttons/AddNewButton';
import BaseModal from 'src/components/BaseModal';
import { rowArrayToObject } from 'src/utils/Util';
import { DefaultPaginationData } from 'src/constants/index';
import { NumberForm } from './Forms';
import BaseSnackbar from 'src/components/BaseSnackbar';
import DeleteContactForm from './Forms/DeleteServiceForm';
import { NumberMaskingService } from 'src/api/services';
import { useTranslation } from 'react-i18next';


const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));


export default function Numbers() {
    const { t } = useTranslation();

    const TABLE_HEAD = [
        { key: "id", label: t("id") },
        { key: "service_name", label: t('service') },
        { key: "name", label: t('name') },
        { key: "masking_number", label: t('mask-number') },
        { key: "uid", label: "UID" },
        { key: "action", label: t('actions') }
    ];
    
    
    const TABLE_FIELD_MAPPING = {
        id: { key: "id", cellComponentType: "th", index: 0 },
        service_name: { key: "service_name", index: 1 },
        name: { key: "name", index: 2 },
        masking_number: { key: "masking_number", index: 3 },
        uid: { key: "uid", index: 4 },
    };
    
    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [message, setMessage] = useState("");
    const [filterServiceName, setFilterServiceName] = useState("");
    const [filterMaskingNumber, setFilterMaskingNumber] = useState("");
    const [openEditModal, setEditModalStatus] = useState(false);
    const [openDeleteModal, setDeleteModalStatus] = useState(false);
    const [openViewModal, setViewModalStatus] = useState(false);
    const [openAddModal, setAddModalStatus] = useState(false);
    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [paginationData, setPaginationData] = useState(DefaultPaginationData);
    const [totalCount, setTotalCount] = useState(0);
    const [loadingData, setLoadingData] = useState(false);

    const fetchMaskingNumbers = () => {
        const params = {
            page: paginationData.page + 1,
            page_size: paginationData.rowsPerPage,
            name: filterServiceName ? filterServiceName : undefined,
            masking_number: filterMaskingNumber ? filterMaskingNumber : undefined,
        };
        setLoadingData(true);
        NumberMaskingService.listMaskingNumbers(params)
            .then((response) => {
                let items = [];
                setTotalCount(response.data.count);
                for (const idx in response.data.results) {
                    let item = new Array(TABLE_HEAD.length - 1).fill({});
                    Object.entries(response.data.results[idx]).forEach(([key, value]) => {
                        if (key in TABLE_FIELD_MAPPING) {
                            item[TABLE_FIELD_MAPPING[key].index] = {
                                ...TABLE_FIELD_MAPPING[key],
                                value: value,
                            };
                        }
                    });
                    items.push(item);
                }
                setData(items);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoadingData(false);
            })
    }

    useEffect(() => {
        fetchMaskingNumbers();
        return () => {
            setData([]);
        }
    }, [paginationData]);

    const formatRowData = (rowData) => {
        let formatted = [];
        rowData.map((d, idx) => {
            formatted.push(d);
        })
        return formatted;
    }

    const modalHandler = (modalType, index = undefined) => {
        if (modalType === "add") {
            setAddModalStatus(!openAddModal);
        } else if (modalType === "edit") {
            setEditModalStatus(!openEditModal);
        } else if (modalType === "delete") {
            setDeleteModalStatus(!openDeleteModal);
        } else if (modalType === "view") {
            setViewModalStatus(!openViewModal)
        }

        if (index) { setSelectedRow(rowArrayToObject(data[index])) };
    }

    const getActionItems = (index) => {
        return (
            <Stack direction="row" spacing={2}>
                <Tooltip title={t('view-contact')}>
                    <IconButton color="secondary" size="small" aria-label="view-contact" onClick={() => modalHandler("view", index)}>
                        <EyeIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={t('edit-contact')}>
                    <IconButton color="secondary" size="small" aria-label="edit-contact" onClick={() => modalHandler("edit", index)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={t('delete-contact')}>
                    <IconButton color="secondary" size="small" aria-label="delete-contact" onClick={() => modalHandler("delete", index)}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Stack>
        )
    }

    return (
        <>
            <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus} />
            <BaseModal title={t('edit-masking-number')} open={openEditModal} setOpen={setEditModalStatus} children={<NumberForm formType="edit" successCallback={fetchMaskingNumbers} formData={selectedRow} setModalStatus={setEditModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseModal title={t('delete-masking-number')} open={openDeleteModal} setOpen={setDeleteModalStatus} children={<DeleteContactForm successCallback={fetchMaskingNumbers} formData={selectedRow} setModalStatus={setDeleteModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseModal title={t('view-masking-number')} open={openViewModal} setOpen={setViewModalStatus} children={<NumberForm formType="view" successCallback={fetchMaskingNumbers} formData={selectedRow} setModalStatus={setEditModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseModal title={t('add-new-masking-number')} open={openAddModal} setOpen={setAddModalStatus} children={<NumberForm formType="add" successCallback={fetchMaskingNumbers} formData={{}} setModalStatus={setAddModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <TableFilterContainer>
                <Grid sx={{ alignItems: "center" }} container spacing={4}>
                    <Grid item md={3} xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                value={filterServiceName}
                                label={t('service-name')}
                                name="serviceName"
                                margin="normal"
                                variant="outlined"
                                color="secondary"
                                onChange={event => { setFilterServiceName(event.target.value) }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                value={filterMaskingNumber}
                                label={t('masking-number')}
                                name="maskingNumber"
                                margin="normal"
                                variant="outlined"
                                color="secondary"
                                onChange={event => { setFilterMaskingNumber(event.target.value) }}
                            />
                        </FormControl>
                    </Grid>
                    <SearchButtonContainer item md={2} xs={12}>
                        <SearchButton onClick={() => { fetchMaskingNumbers() }} />
                    </SearchButtonContainer>
                </Grid>
            </TableFilterContainer>
            <AddNewButton label={t('add-new-masking-number-0')} onClick={() => modalHandler("add")} />
            <br />
            <BaseTable
                head={TABLE_HEAD}
                data={[...data].map((d, idx) => { return formatRowData(d); })}
                actionItemPrep={getActionItems}
                pagination={{ paginationData: { ...paginationData, totalCount: totalCount }, setPaginationData: setPaginationData }}
                loadingData={loadingData}
            />
        </>
    );
}
