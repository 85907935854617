// ----------------------------------------------------------------------
import { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { Grid, TextField, FormControl } from '@mui/material';
import { BaseTable, TableFilterContainer } from 'src/components/table';
import SearchButton from 'src/components/buttons/SearchButton';
import { DefaultPaginationData } from 'src/constants/index';
import { ContactService } from 'src/api/services';
import { useTranslation } from 'react-i18next';


const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));


export default function AssignContactForm({ formData, setModalStatus, setSnackbarStatus, setMessage, successCallback }) {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [paginationData, setPaginationData] = useState(DefaultPaginationData);
    const [totalCount, setTotalCount] = useState(0);
    const [loadingData, setLoadingData] = useState(false);

    const [filterName, setFilterName] = useState("");
    const [filterSurname, setFilterSurname] = useState("");
    const [filterEmail, setFilterEmail] = useState("");
    const [filterPhoneNumber, setFilterPhoneNumber] = useState("");

    const TABLE_HEAD = [
        { key: "select" },
        { key: "id", label: t('id') },
        { key: "first_name", label: t('first-name') },
        { key: "last_name", label: t('last-name') },
        { key: "email", label: t('email') },
        { key: "phone_number", label: t('phone-number') },
        { key: "note", label: t('note') },
    ];

    const TABLE_FIELD_MAPPING = {
        select: { key: "select", index: 0 },
        id: { key: "id", cellComponentType: "th", index: 1 },
        first_name: { key: "first_name", index: 2 },
        last_name: { key: "last_name", index: 3 },
        email: { key: "email", index: 4 },
        phone_number: { key: "phone_number", index: 5 },
        note: { key: "note", index: 6 },
    };

    const fetchContacts = async () => {
        const params = {
            page: paginationData.page + 1,
            page_size: paginationData.rowsPerPage,
            first_name: filterName ? filterName : undefined,
            last_name: filterSurname ? filterSurname : undefined,
            email: filterEmail ? filterEmail : undefined,
            phone_number: filterPhoneNumber ? filterPhoneNumber : undefined,
            contact_group: formData.id,
        };
        setLoadingData(true);
        const items = [];
        try {
            const response = await ContactService.listContacts(params);
            setTotalCount(response.data.count);
            for (const idx in response.data.results) {
                let item = new Array(TABLE_HEAD.length - 1).fill({});
                item[0] = { key: "select", index: 0, value: formData.contacts.map(i => i.id).includes(response.data.results[idx].id) };
                Object.entries(response.data.results[idx]).forEach(([key, value]) => {
                    if (key in TABLE_FIELD_MAPPING) {
                        item[TABLE_FIELD_MAPPING[key].index] = {
                            ...TABLE_FIELD_MAPPING[key],
                            value: value,
                        };
                    }
                });
                items.push(item);
            }
            setData(items);
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setLoadingData(false);
        }
    }

    useEffect(() => {
        fetchContacts();
    }, [paginationData, formData]);

    const formatRowData = (rowData) => {
        let formatted = [];
        rowData.map((d, idx) => {
            formatted.push(d);
        })
        return formatted;
    }

    const onSelectAll = async (checked) => {
        const payload = {
            contact_group_id: formData.id,
            contact_ids: [],
            assign: checked,
        }
        let tempData = [...data];
        for (const idx in tempData) {
            tempData[idx][0].value = checked;
            payload.contact_ids.push(data[idx][1].value)
        }
        const response = await ContactService.assignContactToGroup(payload);
        if (response.status === 200 || response.status === 201)
            setData(tempData);
        else
            throw "assign contact operation failed";
    }

    const onContactSelect = async (checked, index) => {
        const payload = {
            contact_group_id: formData.id,
            contact_ids: [data[index][1].value],
            assign: checked,
        }
        const response = await ContactService.assignContactToGroup(payload);
        if (response.status === 200 || response.status === 201) {
            let tempData = [...data];
            tempData[index][0].value = checked;
            setData(tempData);
        } else { throw "assign contact operation failed"; }
    }

    return (
        <>
            <TableFilterContainer>
                <Grid sx={{ alignItems: "center" }} container spacing={4}>
                    <Grid item md={2} xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                value={filterName}
                                label={t('first-name')}
                                name="name"
                                margin="normal"
                                variant="outlined"
                                color="secondary"
                                onChange={event => { setFilterName(event.target.value) }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={2} xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                value={filterSurname}
                                label={t('last-name')}
                                name="surname"
                                margin="normal"
                                variant="outlined"
                                color="secondary"
                                onChange={event => { setFilterSurname(event.target.value) }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                value={filterEmail}
                                label={t('email')}
                                name="email"
                                margin="normal"
                                variant="outlined"
                                color="secondary"
                                onChange={event => { setFilterEmail(event.target.value) }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                value={filterPhoneNumber}
                                label={t('phone-number')}
                                name="phonenumber"
                                margin="normal"
                                variant="outlined"
                                color="secondary"
                                onChange={event => { setFilterPhoneNumber(event.target.value) }}
                            />
                        </FormControl>
                    </Grid>
                    <SearchButtonContainer item md={2} xs={12}>
                        <SearchButton onClick={() => { fetchContacts() }} />
                    </SearchButtonContainer>
                </Grid>
            </TableFilterContainer>

            <br />
            <BaseTable
                head={TABLE_HEAD}
                data={[...data].map((d, idx) => { return formatRowData(d); })}
                pagination={{ paginationData: { ...paginationData, totalCount: totalCount }, setPaginationData: setPaginationData }}
                loadingData={loadingData}
                select={true}
                onRowSelect={onContactSelect}
                onSelectAll={onSelectAll}
            />
        </>
    );
}

