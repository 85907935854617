// material
import { styled } from '@mui/material/styles';
import { Grid, Typography, Box, Stack } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------

const StyledContainer = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.background.light,
    padding: theme.spacing(4, 4),
    margin: "1px",
    borderRadius: 10,
}));

const StyledLeftGrid = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.background.main,
    padding: theme.spacing(4, 4),
    borderRadius: 10,
    textAlign: "center",
    [theme.breakpoints.up('md')]: {
        marginLeft: "-10px",
        marginRight: "10px",
    },
    [theme.breakpoints.down('md')]: {
        marginBottom: "10px",
    },
}));

const StyledRightGrid = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.background.main,
    padding: theme.spacing(4, 4),
    borderRadius: 10,
    textAlign: "center",
    [theme.breakpoints.up('md')]: {
        marginLeft: "10px",
        marginRight: "-10px",
    },
}));

const StyledSubtitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.secondary.main,
    fontWeight: "bold",
    marginBottom: "4px",
}));

// ----------------------------------------------------------------------

export default function VoiceCdrMapping() {
    const { t } = useTranslation();

    const JAMBONZ_MAPPING = [
        ["to", t('common.__i18n_ally_root__.toNumber')],
        ["from", t('common.fromNumber')],
        ["duration", t('common.duration')],
        ["direction", t('direction')],
        ["attempted_at", t('created')],
        ["terminated_at", t('common.dateRange.end')],
    ];

    const TELESTAX_MAPPING = [
        ["to", t('common.__i18n_ally_root__.toNumber')],
        ["from", t('common.fromNumber')],
        ["status", t('common.__i18n_ally_root__.status')],
        ["duration", t('common.duration')],
        ["direction", t('direction')],
        ["start_time", t('created')],
        ["end_time", t('common.dateRange.end')],
    ];

    const getMappingText = (column, mappedColumn) => {
        return (
            <Grid container alignItems="center" justifyContent="center" spacing={2}>
                <Grid item xs={2}>
                    <Typography color="secondary" variant="p">{column}</Typography>
                </Grid>
                <Grid item xs={2}>
                    <ArrowRightAltIcon size="large" color="secondary" />
                </Grid>
                <Grid item xs={2}>
                    <Typography color="secondary" variant="p">{mappedColumn}</Typography>
                </Grid>
            </Grid>)
    }

    return (
        <>
            <StyledContainer container spacing={4}>
                <StyledLeftGrid sx={{ mr: 5 }} item xs={12} md={6}>
                    <StyledSubtitle variant="h5">{t('jambonz-mapping')}</StyledSubtitle>
                    {JAMBONZ_MAPPING.map(map => getMappingText(map[0], map[1]))}
                </StyledLeftGrid>
                <StyledRightGrid sx={{ mr: 5 }} item xs={12} md={6}>
                    <StyledSubtitle variant="h5">{t('telestax-mapping')}</StyledSubtitle>
                    {TELESTAX_MAPPING.map(map => getMappingText(map[0], map[1]))}
                </StyledRightGrid>
            </StyledContainer>
        </>
    );
}
