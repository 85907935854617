import { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { Grid, TextField, FormControl, Stack, IconButton, Tooltip } from '@mui/material';
import EyeIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { BaseTable, TableFilterContainer } from 'src/components/table';
import SearchButton from 'src/components/buttons/SearchButton';
import AddNewButton from 'src/components/buttons/AddNewButton';
import BaseModal from 'src/components/BaseModal';
import { rowArrayToObject } from 'src/utils/Util';
import { DefaultPaginationData } from 'src/constants/index';
import { AssignContactForm, ContactGroupForm, DeleteContactGroupForm } from './Forms';
import BaseSnackbar from 'src/components/BaseSnackbar';
import { ContactService } from 'src/api/services';
import BaseDialog from 'src/components/BaseDialog';
import { useTranslation } from 'react-i18next';


const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));

const DIALOG_PROPERTIES = {
    fullWidth: true,
    maxWidth: "lg",
    scroll: "body",
    // fullScreen: true,
}


export default function ContactGroups() {
    const { t } = useTranslation();

    const TABLE_HEAD = [
        { key: "id", label: t('id') },
        { key: "group_name", label: t("name") },
        { key: "number_of_contacts", label: t("number-of-contacts") },
        { key: "note", label: t('note') },
        { key: "action", label: t('actions') }
    ];


    const TABLE_FIELD_MAPPING = {
        id: { key: "id", cellComponentType: "th", index: 0 },
        group_name: { key: "group_name", index: 1 },
        number_of_contacts: { key: "number_of_contacts", index: 2 },
        note: { key: "note", index: 3 },
        contacts: { key: "contacts", index: 4, noRender: true }
    };

    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [message, setMessage] = useState("");
    const [filterName, setFilterName] = useState("");
    const [openEditModal, setEditModalStatus] = useState(false);
    const [openDeleteModal, setDeleteModalStatus] = useState(false);
    const [openViewModal, setViewModalStatus] = useState(false);
    const [openAddModal, setAddModalStatus] = useState(false);
    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [paginationData, setPaginationData] = useState(DefaultPaginationData);
    const [totalCount, setTotalCount] = useState(0);
    const [loadingData, setLoadingData] = useState(false);
    const [openAssignContactDialog, setAssignContactDialogStatus] = useState(false);

    const fetchContactGroups = async () => {
        const params = {
            page: paginationData.page + 1,
            page_size: paginationData.rowsPerPage,
            group_name: filterName ? filterName : undefined,
        };
        setLoadingData(true);
        try {
            const items = [];
            const response = await ContactService.listContactGroups(params);
            setTotalCount(response.data.count);
            for (const idx in response.data.results) {
                let item = new Array(TABLE_HEAD.length - 1).fill({});
                item[2] = {
                    key: "number_of_contacts",
                    index: 2,
                    value: response.data.results[idx].contacts.length,
                };
                Object.entries(response.data.results[idx]).forEach(([key, value]) => {
                    if (key in TABLE_FIELD_MAPPING) {
                        item[TABLE_FIELD_MAPPING[key].index] = {
                            ...TABLE_FIELD_MAPPING[key],
                            value: value,
                        };
                    }
                });
                items.push(item);
            }
            setData(items);
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setLoadingData(false);
        }
    }

    useEffect(() => {
        fetchContactGroups();
        return () => {
            setData([]);
        }
    }, [paginationData]);

    const formatRowData = (rowData) => {
        let formatted = [];
        rowData.map((d, idx) => {
            if (d.key === "number_of_contacts") {
                formatted.push({
                    ...d,
                    value: d.value + " " + (d.value > 1 ? t('contacts') : t('contact')),
                });
            } else {
                formatted.push(d);
            }
        })
        return formatted;
    }

    const modalHandler = (modalType, index = undefined) => {
        if (modalType === "add") {
            setAddModalStatus(!openAddModal);
        } else if (modalType === "edit") {
            setEditModalStatus(!openEditModal);
        } else if (modalType === "delete") {
            setDeleteModalStatus(!openDeleteModal);
        } else if (modalType === "view") {
            setViewModalStatus(!openViewModal)
        } else if (modalType === "assignContact") {
            setAssignContactDialogStatus(!openAssignContactDialog)
        }

        if (index) { setSelectedRow(rowArrayToObject(data[index])) };
    }

    const getActionItems = (index) => {
        return (
            <Stack direction="row" spacing={2}>
                <Tooltip title={t('manage-contacts')}>
                    <IconButton color="secondary" size="small" aria-label="manage-contacts" onClick={() => modalHandler("assignContact", index)}>
                        <PersonAddIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={t("view-contact-group")}>
                    <IconButton color="secondary" size="small" aria-label="view-contact" onClick={() => modalHandler("view", index)}>
                        <EyeIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={t('edit-contact-group')}>
                    <IconButton color="secondary" size="small" aria-label="edit-contact" onClick={() => modalHandler("edit", index)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={t('delete-contact-group')}>
                    <IconButton color="secondary" size="small" aria-label="delete-contact" onClick={() => modalHandler("delete", index)}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Stack>
        )
    }

    return (
        <>
            <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus} />
            <BaseModal title={t('edit-contact-group')} open={openEditModal} setOpen={setEditModalStatus} children={<ContactGroupForm formType="edit" successCallback={fetchContactGroups} formData={selectedRow} setModalStatus={setEditModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseModal title={t('delete-contact-group')} open={openDeleteModal} setOpen={setDeleteModalStatus} children={<DeleteContactGroupForm successCallback={fetchContactGroups} formData={selectedRow} setModalStatus={setDeleteModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseModal title={t('view-contact-group')} open={openViewModal} setOpen={setViewModalStatus} children={<ContactGroupForm formType="view" successCallback={fetchContactGroups} formData={selectedRow} setModalStatus={setEditModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseModal title={t('add-new-contact-group')} open={openAddModal} setOpen={setAddModalStatus} children={<ContactGroupForm formType="add" successCallback={fetchContactGroups} formData={{}} setModalStatus={setAddModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseDialog title={t('search-numbers')} dialogProperties={DIALOG_PROPERTIES} closeCallback={fetchContactGroups} open={openAssignContactDialog} setOpen={setAssignContactDialogStatus} children={<AssignContactForm successCallback={fetchContactGroups} formType="add" formData={selectedRow} setModalStatus={setAssignContactDialogStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <TableFilterContainer>
                <Grid sx={{ alignItems: "center" }} container spacing={4}>
                    <Grid item md={4} xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                value={filterName}
                                label={t("name")}
                                name="name"
                                margin="normal"
                                variant="outlined"
                                color="secondary"
                                onChange={event => { setFilterName(event.target.value) }}
                            />
                        </FormControl>
                    </Grid>
                    <SearchButtonContainer item md={2} xs={12}>
                        <SearchButton onClick={() => { fetchContactGroups() }} />
                    </SearchButtonContainer>
                </Grid>
            </TableFilterContainer>
            <AddNewButton label={t('add-new-contact-group')} onClick={() => modalHandler("add")} />
            <br />
            <BaseTable
                head={TABLE_HEAD}
                data={[...data].map((d, idx) => { return formatRowData(d); })}
                actionItemPrep={getActionItems}
                pagination={{ paginationData: { ...paginationData, totalCount: totalCount }, setPaginationData: setPaginationData }}
                loadingData={loadingData}
            />
        </>
    );
}
