import { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { Grid, TextField, FormControl, Stack, IconButton, Tooltip, InputLabel, Select } from '@mui/material';
import EyeIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { BaseTable, TableFilterContainer } from 'src/components/table';
import SearchButton from 'src/components/buttons/SearchButton';
import AddNewButton from 'src/components/buttons/AddNewButton';
import BaseModal from 'src/components/BaseModal';
import BaseDialog from 'src/components/BaseDialog';
import { rowArrayToObject } from 'src/utils/Util';
import { DefaultPaginationData, StatusActiveInactive, getSelectOptions, getLabelByValue } from 'src/constants/index';
import { ServiceForm } from './Forms';
import BaseSnackbar from 'src/components/BaseSnackbar';
import DeleteServiceForm from './Forms/DeleteServiceForm';
import { NumberMaskingService } from 'src/api/services';
import { NumbersDialog, SessionsDialog } from './Dialogs';
import { useTranslation } from 'react-i18next';

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));

const DIALOG_PROPERTIES_SESSIONS = {
    fullWidth: true,
    maxWidth: "lg",
    scroll: "body",
}

const DIALOG_PROPERTIES_NUMBERS = {
    fullWidth: true,
    maxWidth: "sm",
    scroll: "body",
    // fullScreen: true,
}

export default function Services() {
    const { t } = useTranslation();

    const TABLE_HEAD = [
        { key: "id", label: t("id") },
        { key: "name", label: t('service') },
        { key: "uid", label: t('service-id') },
        { key: "numbers_count", label: t('numbers-0') },
        { key: "sessions_count", label: t('sessions') },
        { key: "default_time_to_live", label: t('time-to-live') },
        { key: "status", label: t('common.__i18n_ally_root__.status') },
        { key: "action", label: t('actions') }
    ];
    
    
    const TABLE_FIELD_MAPPING = {
        id: { key: "id", cellComponentType: "th", index: 0 },
        name: { key: "name", index: 1 },
        uid: { key: "uid", index: 2 },
        numbers_count: { key: "numbers_count", index: 3 },
        sessions_count: { key: "sessions_count", index: 4 },
        default_time_to_live: { key: "default_time_to_live", index: 5 },
        status: { key: "status", index: 6 },
        callback_url: { key: "callback_url", index: 7, noRender: true },
        selection_behaviour: { key: "selection_behaviour", index: 8, noRender: true },
        intercept_callback_url: { key: "intercept_callback_url", index: 9, noRender: true },
        out_of_session_callback_url: { key: "out_of_session_callback_url", index: 10, noRender: true },
        sessions: { key: "sessions", index: 11, noRender: true },
        numbers: { key: "numbers", index: 12, noRender: true },
    };
    
    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [message, setMessage] = useState("");
    const [filterServiceName, setFilterServiceName] = useState("");
    const [filterStatus, setFilterStatus] = useState("0");
    const [openEditModal, setEditModalStatus] = useState(false);
    const [openDeleteModal, setDeleteModalStatus] = useState(false);
    const [openViewModal, setViewModalStatus] = useState(false);
    const [openAddModal, setAddModalStatus] = useState(false);
    const [openSessionsModal, setSessionsModalStatus] = useState(false);
    const [openNumbersDialog, setNumbersDialogStatus] = useState(false);
    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [paginationData, setPaginationData] = useState(DefaultPaginationData);
    const [totalCount, setTotalCount] = useState(0);
    const [loadingData, setLoadingData] = useState(false);

    const fetchServices = () => {
        const params = {
            page: paginationData.page + 1,
            page_size: paginationData.rowsPerPage,
            name: filterServiceName ? filterServiceName : undefined,
            status: filterStatus !== "0" ? filterStatus : undefined,
        };
        setLoadingData(true);
        NumberMaskingService.listServices(params)
            .then((response) => {
                let items = [];
                setTotalCount(response.data.count);
                for (const idx in response.data.results) {
                    let item = new Array(TABLE_HEAD.length - 1).fill({});
                    Object.entries(response.data.results[idx]).forEach(([key, value]) => {
                        if (key in TABLE_FIELD_MAPPING) {
                            item[TABLE_FIELD_MAPPING[key].index] = {
                                ...TABLE_FIELD_MAPPING[key],
                                value: value,
                            };
                        }
                    });
                    items.push(item);
                }
                setData(items);
                if (openSessionsModal || openNumbersDialog) {
                    setSelectedRow(rowArrayToObject(items[selectedIndex]));
                }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoadingData(false);
            })
    }

    useEffect(() => {
        fetchServices();
        return () => {
            setData([]);
        }
    }, [paginationData]);

    // useEffect(() => {
    //     if (openSessionsModal) {
    //         setSelectedRow(rowArrayToObject(data[selectedIndex]));
    //         return () => {
    //             setData([]);
    //             setSelectedRow({});
    //         }
    //     }
    // }, [data]);

    const formatRowData = (rowData, index) => {
        let formatted = [];
        rowData.map((d, idx) => {
            if (d.key === "status") {
                formatted.push({
                    ...d,
                    value: getLabelByValue(StatusActiveInactive(), d.value.toString()),
                });
            } else if (d.key === "sessions_count") {
                const onClickMethod = openSessionsModalFunc(index);
                formatted.push({
                    ...d,
                    key: d.key,
                    value: d.value + " " + t('sessions-0'),
                    onClick: onClickMethod,
                });
            } else if (d.key === "numbers_count") {
                const onClickMethod = openNumbersModal(index);
                formatted.push({
                    ...d,
                    key: d.key,
                    value: d.value + " " + t('numbers-0'),
                    onClick: onClickMethod,
                });
            } else {
                formatted.push(d);
            }
        })
        return formatted;
    }

    const modalHandler = (modalType, index = undefined) => {
        if (modalType === "add") {
            setAddModalStatus(!openAddModal);
        } else if (modalType === "edit") {
            setEditModalStatus(!openEditModal);
        } else if (modalType === "delete") {
            setDeleteModalStatus(!openDeleteModal);
        } else if (modalType === "view") {
            setViewModalStatus(!openViewModal)
        }

        if (index) { setSelectedRow(rowArrayToObject(data[index])) };
    }

    const openSessionsModalFunc = (index) => () => {
        setSelectedIndex(index);
        if (index >= 0) { setSelectedRow(rowArrayToObject(data[index])) };
        setSessionsModalStatus(!openSessionsModal);
    }

    const openNumbersModal = (index) => () => {
        setSelectedIndex(index);
        if (index) { setSelectedRow(rowArrayToObject(data[index])) };
        setNumbersDialogStatus(!openNumbersDialog);
    }

    const getActionItems = (index) => {
        return (
            <Stack direction="row" spacing={2}>
                <Tooltip title={t('view-contact')}>
                    <IconButton color="secondary" size="small" aria-label="view-contact" onClick={() => modalHandler("view", index)}>
                        <EyeIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={t('edit-contact')}>
                    <IconButton color="secondary" size="small" aria-label="edit-contact" onClick={() => modalHandler("edit", index)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={t('delete-contact')}>
                    <IconButton color="secondary" size="small" aria-label="delete-contact" onClick={() => modalHandler("delete", index)}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Stack>
        )
    }

    return (
        <>
            <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus} />
            <BaseModal title={t('edit-service')} open={openEditModal} setOpen={setEditModalStatus} children={<ServiceForm formType="edit" successCallback={fetchServices} formData={selectedRow} setModalStatus={setEditModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseModal title={t('delete-service')} open={openDeleteModal} setOpen={setDeleteModalStatus} children={<DeleteServiceForm successCallback={fetchServices} formData={selectedRow} setModalStatus={setDeleteModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseModal title={t('view-service')} open={openViewModal} setOpen={setViewModalStatus} children={<ServiceForm formType="view" successCallback={fetchServices} formData={selectedRow} setModalStatus={setEditModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseModal title={t('add-new-service')} open={openAddModal} setOpen={setAddModalStatus} children={<ServiceForm formType="add" successCallback={fetchServices} formData={{}} setModalStatus={setAddModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseDialog title={t('sessions-1')} dialogProperties={DIALOG_PROPERTIES_SESSIONS} closeCallback={fetchServices} open={openSessionsModal} setOpen={setSessionsModalStatus} children={<SessionsDialog successCallback={fetchServices} formType="add" formData={selectedRow} setModalStatus={setSessionsModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseDialog title={t('numbers-0')} dialogProperties={DIALOG_PROPERTIES_NUMBERS} closeCallback={fetchServices} open={openNumbersDialog} setOpen={setNumbersDialogStatus} children={<NumbersDialog successCallback={fetchServices} formType="add" formData={selectedRow} setModalStatus={setNumbersDialogStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />

            <TableFilterContainer>
                <Grid sx={{ alignItems: "center" }} container spacing={4}>
                    <Grid item md={3} xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                value={filterServiceName}
                                label={t('service-name')}
                                name="serviceName"
                                margin="normal"
                                variant="outlined"
                                color="secondary"
                                onChange={event => { setFilterServiceName(event.target.value) }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={2} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-status-label">{t('common.__i18n_ally_root__.status')}</InputLabel>
                            <Select
                                label={t('common.__i18n_ally_root__.status')}
                                labelId="filter-status-label"
                                name="type"
                                color="secondary"
                                value={filterStatus}
                                onChange={event => { setFilterStatus(event.target.value) }}
                            >
                                {getSelectOptions(StatusActiveInactive())}
                            </Select>
                        </FormControl>
                    </Grid>
                    <SearchButtonContainer item md={2} xs={12}>
                        <SearchButton onClick={() => { fetchServices() }} />
                    </SearchButtonContainer>
                </Grid>
            </TableFilterContainer>
            <AddNewButton label={t('add-new-service-0')} onClick={() => modalHandler("add")} />
            <br />
            <BaseTable
                head={TABLE_HEAD}
                data={[...data].map((d, idx) => { return formatRowData(d, idx); })}
                actionItemPrep={getActionItems}
                pagination={{ paginationData: { ...paginationData, totalCount: totalCount }, setPaginationData: setPaginationData }}
                loadingData={loadingData}
            />
        </>
    );
}
