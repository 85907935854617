import SmartcpaasAppLayout from 'src/layouts/SmartcpaasAppLayout';
import { Campaign, Announcement, Report } from './campaignManagement/';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------


export default function CampaignManagement() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: t('campaign'), component: <Campaign /> },
        { id: 1, label: t('announcement'), component: <Announcement /> },
        { id: 2, label: t('report'), component: <Report /> },
        { id: 3, label: t('log'), component: "LogComponent" },
    ];
    
    const TITLE = t('campaign-management');
    
    return (
        <SmartcpaasAppLayout tabs={TABS} title={TITLE} />
    );
}
