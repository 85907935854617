import { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { Grid, TextField, FormControl, Stack, IconButton, Tooltip, InputLabel, Select } from '@mui/material';
import EyeIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { BaseTable, TableFilterContainer } from 'src/components/table';
import SearchButton from 'src/components/buttons/SearchButton';
import AddNewButton from 'src/components/buttons/AddNewButton';
import BaseModal from 'src/components/BaseModal';
import { rowArrayToObject } from 'src/utils/Util';
import { DefaultPaginationData, StatusActiveInactive, getSelectOptions, getLabelByValue } from 'src/constants/index';
import { ParticipantForm, SessionForm } from '../Forms';
import DeleteServiceForm from '../Forms/DeleteServiceForm';
import { fDateTime } from 'src/utils/formatTime';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));

export default function SessionsDialog({ formData, setModalStatus, setSnackbarStatus, setMessage, successCallback }) {
    const { t } = useTranslation();
    const TABLE_HEAD = [
        { key: "id", label: t('id') },
        { key: "name", label: t('session') },
        { key: "created", label: t('created-at') },
        { key: "expires_at", label: t('expires-at') },
        { key: "default_time_to_live", label: t('time-to-live') },
        { key: "status", label: t('common.__i18n_ally_root__.status') },
        { key: "action", label: t('actions') }
    ];
    
    const TABLE_FIELD_MAPPING = {
        id: { key: "id", cellComponentType: "th", index: 0 },
        name: { key: "name", index: 1 },
        created: { key: "created", index: 2 },
        expires_at: { key: "expires_at", index: 3 },
        default_time_to_live: { key: "default_time_to_live", index: 4 },
        status: { key: "status", index: 5 },
        masking_number_value: { key: "masking_number_value", index: 6, noRender: true },
        first_phone_number: { key: "first_phone_number", index: 7, noRender: true },
        second_phone_number: { key: "second_phone_number", index: 8, noRender: true },
    };
    
    const [filterSessionName, setFilterSessionName] = useState("");
    const [filterStatus, setFilterStatus] = useState("0");
    const [openEditModal, setEditModalStatus] = useState(false);
    const [openDeleteModal, setDeleteModalStatus] = useState(false);
    const [openViewModal, setViewModalStatus] = useState(false);
    const [openAddModal, setAddModalStatus] = useState(false);
    const [openAddParticipantModal, setAddParticipantModalStatus] = useState(false);
    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [paginationData, setPaginationData] = useState(DefaultPaginationData);
    const [totalCount, setTotalCount] = useState(0);
    const [loadingData, setLoadingData] = useState(false);
    const [loading, setLoading] = useState(false);

    const loadSessions = () => {
        if (formData.sessions !== undefined) {
            const params = {
                name: filterSessionName ? filterSessionName : '',
                status: filterStatus !== "0" ? +filterStatus : undefined,
            };
            const data = formData.sessions.filter(i =>
                i.name.toUpperCase().includes(params.name.toUpperCase()) &&
                (params.status === undefined || i.status === params.status));
            setLoadingData(true);
            let items = [];
            for (const idx in data) {
                let item = new Array(TABLE_HEAD.length - 1).fill({});
                Object.entries(data[idx]).forEach(([key, value]) => {
                    if (key in TABLE_FIELD_MAPPING) {
                        item[TABLE_FIELD_MAPPING[key].index] = {
                            ...TABLE_FIELD_MAPPING[key],
                            value: value,
                        };
                    }
                });
                items.push(item);
            }
            setData(items);
            setLoadingData(false);
        }
    }

    useEffect(() => {
        loadSessions();
        return () => {
            setData([]);
        }
    }, [paginationData, formData]);

    const formatRowData = (rowData) => {
        let formatted = [];
        rowData.map((d, idx) => {
            if (d.key === "status") {
                formatted.push({
                    ...d,
                    value: getLabelByValue(StatusActiveInactive(), d.value.toString()),
                });
            } else if (d.key === "created") {
                formatted.push({
                    ...d,
                    value: fDateTime(+d.value * 1000),
                });
            } else if (d.key === "expires_at") {
                formatted.push({
                    ...d,
                    value: fDateTime(+d.value * 1000),
                });
            } else {
                formatted.push(d);
            }
        })
        return formatted;
    }

    const modalHandler = (modalType, index = undefined) => {
        if (modalType === "add") {
            setAddModalStatus(!openAddModal);
        } else if (modalType === "edit") {
            setEditModalStatus(!openEditModal);
        } else if (modalType === "delete") {
            setDeleteModalStatus(!openDeleteModal);
        } else if (modalType === "view") {
            setViewModalStatus(!openViewModal)
        } else if (modalType === "addParticipant") {
            setAddParticipantModalStatus(!openAddParticipantModal)
        }

        if (index) {
            setSelectedRow({ ...rowArrayToObject(data[index]), service: formData.uid })
        };
    }

    const getActionItems = (index) => {
        return (
            <Stack direction="row" spacing={2}>
                <Tooltip title={t('view-session')}>
                    <IconButton color="secondary" size="small" aria-label="view-session" onClick={() => modalHandler("view", index)}>
                        <EyeIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={t('edit-session')}>
                    <IconButton color="secondary" size="small" aria-label="edit-session" onClick={() => modalHandler("edit", index)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={t('delete-session')}>
                    <IconButton color="secondary" size="small" aria-label="delete-session" onClick={() => modalHandler("delete", index)}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Stack>
        )
    }

    return (
        <>
            <BaseModal title={t('edit-session-0')} open={openEditModal} setOpen={setEditModalStatus} children={<SessionForm formType="edit" successCallback={successCallback} formData={{ ...selectedRow, numbers: formData.numbers }} setModalStatus={setEditModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseModal title={t('delete-session-0')} open={openDeleteModal} setOpen={setDeleteModalStatus} children={<DeleteServiceForm successCallback={successCallback} formData={selectedRow} setModalStatus={setDeleteModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseModal title={t('view-session-0')} open={openViewModal} setOpen={setViewModalStatus} children={<SessionForm formType="view" successCallback={successCallback} formData={{ ...selectedRow, numbers: formData.numbers }} setModalStatus={setEditModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseModal title={t('add-new-session')} open={openAddModal} setOpen={setAddModalStatus} children={<SessionForm formType="add" successCallback={successCallback} formData={{ service: formData.uid, numbers: formData.numbers }} setModalStatus={setAddModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseModal title={t('add-participants')} open={openAddParticipantModal} setOpen={setAddParticipantModalStatus} children={<ParticipantForm formType="add" successCallback={successCallback} formData={{ ...selectedRow, numbers: formData.numbers }} setModalStatus={setAddParticipantModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <TableFilterContainer>
                <Grid sx={{ alignItems: "center" }} container spacing={4}>
                    <Grid item md={3} xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                value={filterSessionName}
                                label={t('session-name')}
                                name="sessionName"
                                margin="normal"
                                variant="outlined"
                                color="secondary"
                                onChange={event => { setFilterSessionName(event.target.value) }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={2} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-status-label">{t('common.__i18n_ally_root__.status')}</InputLabel>
                            <Select
                                label={t('common.__i18n_ally_root__.status')}
                                labelId="filter-status-label"
                                name="type"
                                color="secondary"
                                value={filterStatus}
                                onChange={event => { setFilterStatus(event.target.value) }}
                            >
                                {getSelectOptions(StatusActiveInactive())}
                            </Select>
                        </FormControl>
                    </Grid>
                    <SearchButtonContainer item md={2} xs={12}>
                        <SearchButton onClick={() => { loadSessions() }} />
                    </SearchButtonContainer>
                </Grid>
            </TableFilterContainer>
            <AddNewButton label={t('add-new-session-0')} onClick={() => modalHandler("add")} />
            <br />
            <BaseTable
                head={TABLE_HEAD}
                data={[...data].map((d, idx) => { return formatRowData(d); })}
                actionItemPrep={getActionItems}
                pagination={{ paginationData: { ...paginationData, totalCount: totalCount }, setPaginationData: setPaginationData }}
                loadingData={loadingData}
            />
        </>
    );
}
