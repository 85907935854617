import { useState } from 'react';
// material
import {
    Stack,
    Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// app
import { ContactService } from 'src/api/services';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function DeleteContactForm({ formData, setModalStatus, setSnackbarStatus, setMessage, successCallback }) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const deleteContact = () => {
        setLoading(true);
        ContactService.deleteContact(formData.id)
            .then((response) => {
                if (response.status === 200) {
                    setMessage(t('has-been-successfully-deleted', { name: `${formData.first_name} ${formData.last_name}` }))
                    setSnackbarStatus(true);
                    setModalStatus(false);
                    setLoading(false);
                    successCallback();
                } else {
                    throw "contact could not be deleted";
                }
            })
            .catch((err) => {
                setMessage(t('could-not-be-deleted', { name: `${formData.first_name} ${formData.last_name}` }))
                setSnackbarStatus(true);
                setModalStatus(false);
                setLoading(false);
            });
    }

    return (
        <>
            <Stack spacing={3}>
                <Typography component="h6">
                    {t('are-you-sure')}
                </Typography>
                <Typography component="subtitle1" variant="h6">
                    {formData.first_name} {formData.last_name}
                </Typography>
                <Stack sx={{ display: "block" }} direction="row" spacing={2}>
                    <LoadingButton
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={loading}
                        onClick={() => setModalStatus(false)}
                    >
                        {t('cancel')}
                    </LoadingButton>
                    <LoadingButton
                        type="submit"
                        color="secondary"
                        variant="contained"
                        loading={loading}
                        onClick={deleteContact}
                    >
                        {t('delete')}
                    </LoadingButton>
                </Stack>
            </Stack>
        </>
    );
}
