import { useState, useEffect } from 'react';
import { BaseTable } from 'src/components/table';
import { DefaultPaginationData } from 'src/constants/index';
import { fDateTime } from 'src/utils/formatTime';
import { useTranslation } from 'react-i18next';


export default function CustomApplication() {
    const { t } = useTranslation();
    const TABLE_HEAD = [
        { key: "sid", label: t("sid") },
        { key: "name", label: t('name') },
        { key: "account", label: t('engine') },
        { key: "created", label: t('created') },
    ];
    
    const TABLE_FIELD_MAPPING = {
        sid: { key: "sid", cellComponentType: "th", index: 0 },
        name: { key: "name", index: 1 },
        account: { key: "account", index: 2 },
        created: { key: "created", index: 3 },
    };
    
    const DUMMY_DATA = [
        {
            "sid": "AP81b4e38f1b6346e69a4758ad54229a14",
            "name": "SMS Echo 1560175518",
            "account": "Telestax - EU",
            "created": 1560175520
        }
    ]
    
    const [data, setData] = useState([]);
    const [paginationData, setPaginationData] = useState(DefaultPaginationData);
    const [totalCount, setTotalCount] = useState(0);
    const [loadingData, setLoadingData] = useState(false);

    const fetchCustomApplications = () => {
        const params = {
            page: paginationData.page + 1,
            page_size: paginationData.rowsPerPage,
        };
        setLoadingData(true);
        // TODO: fetch custom applications from server
        setTimeout(() => {
            let items = [];
            setTotalCount(DUMMY_DATA.length);
            for (const idx in DUMMY_DATA) {
                let item = new Array(TABLE_HEAD.length - 1).fill({});
                Object.entries(DUMMY_DATA[idx]).forEach(([key, value]) => {
                    if (key in TABLE_FIELD_MAPPING) {
                        item[TABLE_FIELD_MAPPING[key].index] = {
                            ...TABLE_FIELD_MAPPING[key],
                            value: value,
                        };
                    }
                });
                items.push(item);
            }
            setData(items);
            setLoadingData(false);
        }, 1000);
    }

    useEffect(() => {
        fetchCustomApplications();
        return () => {
            setData([]);
        }
    }, [paginationData]);

    const formatRowData = (rowData) => {
        let formatted = [];
        rowData.map((d, idx) => {
            if (d.key === "created") {
                formatted.push({
                    ...d,
                    value: fDateTime(+d.value * 1000),
                });
            } else {
                formatted.push(d);
            }
        })
        return formatted;
    }

    return (
        <>
            <BaseTable
                head={TABLE_HEAD}
                data={[...data].map((d, idx) => { return formatRowData(d); })}
                pagination={{ paginationData: { ...paginationData, totalCount: totalCount }, setPaginationData: setPaginationData }}
                loadingData={loadingData}
            />
        </>
    );
}
