import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// material
import { useTheme, styled } from '@mui/material/styles';
import { Grid, Typography, Stack, FormControl, Autocomplete, Checkbox, TextField, Box, Popper, ButtonBase, InputBase, Alert } from '@mui/material';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import SettingsIcon from '@mui/icons-material/Settings';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
// app
import { useStore } from 'src/store/Store';
import { SiteConfigService } from 'src/api/services';
import { LoadingButton } from '@mui/lab';
import BaseSnackbar from 'src/components/BaseSnackbar';
import { useTranslation } from 'react-i18next';
import BaseButton from 'src/components/buttons/BaseButton';
// ----------------------------------------------------------------------

const StyledContainer = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.background.light,
    padding: theme.spacing(4, 4),
    margin: "1px",
    borderRadius: 10,
    justifyContent: 'center',
}));

const StyledSupportedLanguageGrid = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.background.main,
    padding: theme.spacing(4, 4),
    borderRadius: 10,
    [theme.breakpoints.up('md')]: {
        marginLeft: "-10px",
        marginRight: "10px",
    },
    [theme.breakpoints.down('md')]: {
        marginBottom: "10px",
    },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.secondary.main,
    fontWeight: "bold",
    marginBottom: "4px"
}));

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
    [`& .${autocompleteClasses.paper}`]: {
        boxShadow: 'none',
        margin: 0,
        color: 'inherit',
        fontSize: 13,
    },
    [`& .${autocompleteClasses.listbox}`]: {
        backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
        padding: 0,
        [`& .${autocompleteClasses.option}`]: {
            minHeight: 'auto',
            alignItems: 'flex-start',
            padding: 8,
            borderBottom: `1px solid  ${theme.palette.mode === 'light' ? ' #eaecef' : '#30363d'
                }`,
            '&[aria-selected="true"]': {
                backgroundColor: 'transparent',
            },
            '&[data-focus="true"], &[data-focus="true"][aria-selected="true"]': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    },
    [`&.${autocompleteClasses.popperDisablePortal}`]: {
        position: 'relative',
    },
}));

function PopperComponent(props) {
    const { disablePortal, anchorEl, open, ...other } = props;
    return <StyledAutocompletePopper {...other} />;
}

PopperComponent.propTypes = {
    anchorEl: PropTypes.any,
    disablePortal: PropTypes.bool,
    open: PropTypes.bool.isRequired,
};

const StyledPopper = styled(Popper)(({ theme }) => ({
    border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
    boxShadow: `0 8px 24px ${theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
        }`,
    borderRadius: 6,
    width: '100%',
    maxWidth: 400,
    zIndex: theme.zIndex.modal,
    // fontSize: 13,
    color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
    padding: 10,
    width: '100%',
    borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'
        }`,
    '& input': {
        borderRadius: 4,
        backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#0d1117',
        padding: 8,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        border: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
        fontSize: 14,
        '&:focus': {
            boxShadow: `0px 0px 0px 3px ${theme.palette.mode === 'light'
                ? 'rgba(3, 102, 214, 0.3)'
                : 'rgb(12, 45, 107)'
                }`,
            borderColor: theme.palette.mode === 'light' ? '#0366d6' : '#388bfd',
        },
    },
}));


// ----------------------------------------------------------------------

export default function SupportedLanguage() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [pendingValue, setPendingValue] = useState([]);
    const theme = useTheme();
    const { t } = useTranslation();
    const [store, dispatch] = useStore();
    const [languages, setLanguages] = useState([]);
    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [message, setMessage] = useState("");

    const SupportedLanguageSchema = Yup.object().shape({
        supported_languages: Yup.array()
            .min(1, t('a-language-is-required'))
    });

    const formik = useFormik({
        initialValues: {
            supported_languages: store.supported_languages.map(lang => lang.id)
        },
        validationSchema: SupportedLanguageSchema,
        onSubmit: (values, actions) => {
            const payload = {
                supported_language_ids: values.supported_languages,
            }
            SiteConfigService.updateSupportedLanguages(payload)
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        dispatch({
                            type: "UPDATE_SUPPORTED_LANGUAGES",
                            payload: {
                                supported_languages: response.data.supported_languages,
                            }
                        });
                        setMessage(t('supported-languages-has-been-successfully-updated'));
                        setSnackbarStatus(true);
                    } else { throw "update supported languages failed"; }
                })
                .catch((err) => {
                    setMessage(t('supported-languages-could-not-be-updated'));
                    setSnackbarStatus(true);
                })
                .finally(() => {
                    actions.setSubmitting(false);
                })
        }
    });

    useEffect(() => {
        SiteConfigService.getAllSupportedLanguages()
            .then((response) => {
                setLanguages(response.data.results);
            })
            .catch((err) => {
                console.log(err);
            })
    }, []);

    useEffect(() => {
        setPendingValue(languages.filter(i => store.supported_languages.map(j => j.id).includes(i.id)));
    }, [languages]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setFieldValue("supported_languages", pendingValue.map(i => i.id));
        if (anchorEl) {
            anchorEl.focus();
        }
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'github-label' : undefined;

    const { errors, touched, isSubmitting, handleSubmit, setFieldValue } = formik;

    return (
        <>
            <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus} />
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <StyledContainer container spacing={4}>
                        <StyledSupportedLanguageGrid sx={{ mr: 5 }} item xs={12} md={8}>
                            <Stack spacing={1}>
                                <StyledTypography variant="h4">{t('select-supported-languages')}</StyledTypography>
                                <Box sx={{ paddingLeft: '8px', width: '100%', alignSelf: 'center', maxWidth: '400px' }}>
                                    <BaseButton sx={{ width: '100%' }} label={t('select-supported-languages')} EndIcon={SettingsIcon} disableRipple aria-describedby={id} onClick={handleClick}>
                                    </BaseButton>
                                </Box>
                                {Boolean(touched.supported_languages && errors.supported_languages) &&
                                    (<Alert sx={{ width: '100%', alignSelf: 'center', maxWidth: '400px' }} severity="error">{touched.supported_languages && errors.supported_languages}</Alert>)
                                }
                                <Grid sx={{ paddingRight: '8px', justifyContent: 'center' }} container spacing={1}>
                                    {pendingValue.map((language) => (
                                        <Grid item xs={12} md={4}>
                                            <Box
                                                key={language.id}
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    height: 36,
                                                    padding: '.15em 4px',
                                                    borderRadius: '8px',
                                                }}
                                                style={{
                                                    backgroundColor: theme.palette.background.light,
                                                    color: theme.palette.getContrastText(theme.palette.background.light),
                                                }}
                                            >
                                                <Box component="img" sx={{ width: 32, height: 32 }} alt={language.name} src={language.icon_url} />
                                                <Typography sx={{ flexGrow: 1, textAlign: 'center' }}>{language.name}</Typography>
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                                <Box style={{ textAlign: "right" }}>
                                    <LoadingButton
                                        color="secondary"
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        loading={isSubmitting}
                                    >
                                        {t('update')}
                                    </LoadingButton>
                                </Box>
                                <StyledPopper id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <div>
                                            <Autocomplete
                                                open
                                                multiple
                                                onClose={(event, reason) => {
                                                    if (reason === 'escape') {
                                                        handleClose();
                                                    }
                                                }}
                                                value={pendingValue}
                                                onChange={(event, newValue, reason) => {
                                                    if (
                                                        event.type === 'keydown' &&
                                                        event.key === 'Backspace' &&
                                                        reason === 'removeOption'
                                                    ) {
                                                        return;
                                                    }
                                                    setPendingValue(newValue);
                                                }}
                                                disableCloseOnSelect
                                                PopperComponent={PopperComponent}
                                                renderTags={() => null}
                                                noOptionsText="No labels"
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}>
                                                        <Box
                                                            component={DoneIcon}
                                                            sx={{ width: 17, height: 17, mr: '5px', ml: '-2px' }}
                                                            style={{
                                                                visibility: selected ? 'visible' : 'hidden',
                                                            }}
                                                        />
                                                        <Box
                                                            sx={{
                                                                flexGrow: 1,
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                '& span': {
                                                                    color:
                                                                        theme.palette.mode === 'light' ? '#586069' : '#8b949e',
                                                                },
                                                                textAlign: 'center',
                                                            }}
                                                        >
                                                            <Box component="img" sx={{ width: 32, height: 32 }} alt={option.name} src={option.icon_url} />
                                                            <Typography sx={{ flexGrow: 1 }}>
                                                                {option.name}
                                                                <br />
                                                                <span>{option.local_name}</span>
                                                            </Typography>
                                                        </Box>
                                                        <Box
                                                            component={CloseIcon}
                                                            sx={{ opacity: 0.6, width: 18, height: 18 }}
                                                            style={{
                                                                visibility: selected ? 'visible' : 'hidden',
                                                            }}
                                                        />
                                                    </li>
                                                )}
                                                options={[...languages].sort((a, b) => {
                                                    // Display the selected labels first.
                                                    let ai = pendingValue.indexOf(a);
                                                    ai = ai === -1 ? pendingValue.length + languages.indexOf(a) : ai;
                                                    let bi = pendingValue.indexOf(b);
                                                    bi = bi === -1 ? pendingValue.length + languages.indexOf(b) : bi;
                                                    return ai - bi;
                                                })}
                                                getOptionLabel={(option) => option.name}
                                                renderInput={(params) => (
                                                    <StyledInput
                                                        ref={params.InputProps.ref}
                                                        inputProps={params.inputProps}
                                                        autoFocus
                                                        placeholder={t('common.search')}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </ClickAwayListener>
                                </StyledPopper>
                            </Stack>
                        </StyledSupportedLanguageGrid>
                    </StyledContainer>
                </Form>
            </FormikProvider>
        </>
    );
}
