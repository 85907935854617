import BaseButton from 'src/components/buttons/BaseButton';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';


export default function AdvancedCampaignDialog({ open, setOpen, setIsProgrammable }) {
    const { t } = useTranslation();

    const handleClose = (isProgrammable) => {
        setIsProgrammable(isProgrammable);
        setOpen(false);
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={() => handleClose(false)}
                aria-labelledby="advanced-campaign-dialog-title"
                aria-describedby="advanced-campaign-dialog-description"
            >
                <DialogTitle id="advanced-campaign-dialog-title">
                    {t('use-programmable-campaign-designer')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="advanced-campaign-dialog-description">
                        {t('programmable-campaign-designer-lets-you-customize-the-sms-body-per-destination-number')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <BaseButton label={t('no')} onClick={() => handleClose(false)} />
                    <BaseButton label={t('yes')} onClick={() => handleClose(true)} props={{ autoFocus: true }} />
                </DialogActions>
            </Dialog>
        </>
    );
}
