import SmartcpaasAppLayout from 'src/layouts/SmartcpaasAppLayout';
import { CallForwardings, SendSms } from './callForwarding/';
import { useTranslation } from 'react-i18next';
import { Cdr } from '../logViewer';
// ----------------------------------------------------------------------


export default function CallForwarding() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: t('numbers'), component: <CallForwardings /> },
        { id: 1, label: t("common.programmableSms.sendSms"), component: <SendSms /> },
        { id: 2, label: t('log'), component: <Cdr cdrType="voice" serviceType="17"/> }
    ];

    const TITLE = t('call-forwarding');

    return (
        <SmartcpaasAppLayout tabs={TABS} title={TITLE} />
    );
}
