import * as Yup from "yup";
import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { useFormik, Form, FormikProvider } from "formik";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Box,
  Autocomplete,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// ----------------------------------------------------------------------
import BaseSnackbar from "src/components/BaseSnackbar";
import { CountriesService, CustomerService } from "src/api/services";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingSpinner from "src/components/animatedComponenets/LaodingSpinner";

// ----------------------------------------------------------------------

export default function AcceptCustomerUserInvitation({
  setModalStatus,
  setSnackbarStatus,
  setExternalMessage,
  successCallback,
  formType = "register",
  mainPage = false,
}) {
  const { t } = useTranslation();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const email = queryParams.get("email");
  const token = queryParams.get("token");
  const [showPassword, setShowPassword] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [navigating, setNavigating] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [countries, setCountries] = useState([]);

  const navigate = useNavigate();
  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, t("too-short-0"))
      .max(50, t("too-long-0"))
      .required(t("first-name-is-required"))
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),

    lastName: Yup.string()
      .min(2, t("too-short-1"))
      .max(50, t("too-long-1"))
      .required(t("last-name-is-required"))
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),
    telephone: Yup.number().required(t("telephone-is-required")),
    address1: Yup.string().required(t("address-is-required")),
    address2: Yup.string().required(t("address-2-is-required")),
    password: Yup.string()
      .required(t("password-is-required"))
      .min(8, t("too-short-3"))
      .max(32, t("password-long-error"))
      .matches(/^[\S]*$/, t("password-validation")),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], t("passwords-must-match"))
      .required(t("confirm-password-required")),
    country: Yup.number().required(t("country-is-required")),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      password: "",
      telephone: "",
      address1: "",
      address2: "",
      country: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: (values, actions) => {
      const payload = {
        email: email,
        token: token,
        password: values.password,
        first_name: values.firstName,
        last_name: values.lastName,
        address1: values.address1,
        address2: values.address2,
        telephone: values.telephone,
        country_id: values.country,
      };

      CustomerService.acceptCustomerUserInvitation(payload)
        .then((response) => {
          if (response.status === 200) {
            if (formType === "register") {
              setMessage(t("you-have-successfully-registered"));
              setOpenSnackbar(true);
              setNavigating(true);
              setTimeout(() => {
                navigate("/login");
              }, 2000);
            } else {
              if (setExternalMessage) {
                setExternalMessage(
                  t(
                    "customer-and-its-initial-user-has-been-successfully-created"
                  )
                );
              }
              if (setSnackbarStatus) {
                setSnackbarStatus(true);
              }
              if (setModalStatus) {
                setModalStatus(false);
              }
              if (successCallback) {
                successCallback();
              }
            }
          } else {
            throw t("register-failed");
          }
        })
        .catch((err) => {
          if (formType === "register") {
            if (err.response.data && err.response.data.detail) {
              setMessage(err.response.data.detail);
            } else {
              // Set a generic error message if the response does not provide one
              setMessage(t("register-failed"));
            }
            setOpenSnackbar(true);
          } else {
            if (setExternalMessage) {
              setExternalMessage(
                t("customer-and-its-initial-user-could-not-be-created")
              );
            }
            if (setSnackbarStatus) {
              setSnackbarStatus(true);
            }
            if (setModalStatus) {
              setModalStatus(false);
            }
          }
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    },
  });

  const fetchCountries = () => {
    CountriesService.getCountries().then((response) => {
      if (response.status === 200) {
        let countryOptions = response.data.map((country) => ({
          value: country.id,
          label: country.name,
        }));

        setCountries(countryOptions);
      } else {
        throw "error listing countries";
      }
    });
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setOpenSnackbar}
      />
      {navigating ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Box>
            <LoadingSpinner message={t("navigating-to-login-page")} />
          </Box>
        </Box>
      ) : (
        <>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={1}>
                <Box mt={2}>
                  <TextField
                    fullWidth
                    type="email"
                    label={t("email-address")}
                    value={email}
                    disabled
                  />
                </Box>
                <Stack direction={{ xs: "column", sm: "row" }}>
                  <TextField
                    fullWidth
                    label={t("first-name-0")}
                    {...getFieldProps("firstName")}
                    error={Boolean(touched.firstName && errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                    sx={{ mr: 2 }}
                  />

                  <TextField
                    fullWidth
                    label={t("last-name-0")}
                    {...getFieldProps("lastName")}
                    error={Boolean(touched.lastName && errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                  />
                </Stack>
                <Stack direction={{ xs: "column", sm: "row" }}>
                  <TextField
                    fullWidth
                    label={t("telephone")}
                    {...getFieldProps("telephone")}
                    error={Boolean(touched.telephone && errors.telephone)}
                    helperText={touched.telephone && errors.telephone}
                  />
                </Stack>

                <Stack direction={{ xs: "column", sm: "row" }}>
                  <TextField
                    fullWidth
                    label={t("address-1")}
                    {...getFieldProps("address1")}
                    error={Boolean(touched.address1 && errors.address1)}
                    helperText={touched.address1 && errors.address1}
                    sx={{ mr: 2 }}
                  />
                  <TextField
                    fullWidth
                    type="email"
                    label={t("address-2")}
                    {...getFieldProps("address2")}
                    error={Boolean(touched.address2 && errors.address2)}
                    helperText={touched.address2 && errors.address2}
                  />
                </Stack>

                <Autocomplete
                  id="countries"
                  options={countries}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("select-country")}
                      error={Boolean(touched.country && errors.country)}
                      helperText={touched.country && errors.country}
                    />
                  )}
                  value={
                    countries.find(
                      (option) => option.value === selectedCountry
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    const countryValue = newValue
                      ? Number(newValue.value)
                      : null;
                    setSelectedCountry(countryValue);
                    formik.setFieldValue("country", countryValue);
                  }}
                />
                <TextField
                  fullWidth
                  type={showPassword ? "text" : "password"}
                  label={t("password")}
                  {...getFieldProps("password")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={() => setShowPassword((prev) => !prev)}
                        >
                          <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                />

                <TextField
                  fullWidth
                  autoComplete="confirm-password"
                  type={showPassword ? "text" : "password"}
                  label={t("confirm-password")}
                  {...getFieldProps("confirmPassword")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword} edge="end">
                          <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(
                    touched.confirmPassword && errors.confirmPassword
                  )}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                />
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  {t("register-0")}
                </LoadingButton>
              </Stack>
            </Form>
          </FormikProvider>
        </>
      )}
    </>
  );
}
