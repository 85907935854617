import { useState, useEffect } from 'react';
import { Stack, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { BaseTable } from 'src/components/table';
import AddNewButton from 'src/components/buttons/AddNewButton';
import BaseModal from 'src/components/BaseModal';
import { rowArrayToObject } from 'src/utils/Util';
import { DefaultPaginationData } from 'src/constants/index';
import { TFATariffForm, DeleteTariffForm } from './Forms';
import { TariffService } from 'src/api/services';
import BaseSnackbar from 'src/components/BaseSnackbar';
import { fDateTime } from 'src/utils/formatTime';
import { useTranslation } from 'react-i18next';


export default function TFATariff() {
    const { t } = useTranslation();

    const TABLE_HEAD = [
        { key: "id", label: t("id") },
        { key: "tariffPackageName", label: t("tariff-package") },
        { key: "rate", label: t('rate') },
        { key: "effective_start", label: t('effective-start') },
        { key: "action", label: t('actions') },
    ];
    
    const TABLE_FIELD_MAPPING = {
        id: { key: "id", cellComponentType: "th", index: 0 },
        tariff_package_name: { key: "tariffPackageName", index: 1 },
        rate: { key: "rate", index: 2 },
        effective_start: { key: "effectiveStart", index: 3 },
        effective_end: { key: "effectiveEnd", index: 4, noRender: true },
        tariff_package: { key: "tariffPackage", index: 5, noRender: true },
    };
    
    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [message, setMessage] = useState("");
    const [openEditModal, setEditModalStatus] = useState(false);
    const [openDeleteModal, setDeleteModalStatus] = useState(false);
    const [openAddTariffModal, setAddTariffModalStatus] = useState(false);
    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [paginationData, setPaginationData] = useState(DefaultPaginationData);
    const [totalCount, setTotalCount] = useState(0);
    const [loadingData, setLoadingData] = useState(false);

    const fetchTariffs = () => {
        const params = {
            page: paginationData.page + 1,
            page_size: paginationData.rowsPerPage,
        };
        setLoadingData(true);
        const apiService = TariffService.listTFATariffs(params);
        apiService
            .then((response) => {
                let items = [];
                setTotalCount(response.data.count);
                for (const idx in response.data.results) {
                    let item = new Array(TABLE_HEAD.length - 1).fill({});
                    Object.entries(response.data.results[idx]).forEach(([key, value]) => {
                        if (key in TABLE_FIELD_MAPPING) {
                            item[TABLE_FIELD_MAPPING[key].index] = {
                                ...TABLE_FIELD_MAPPING[key],
                                value: value,
                            };
                        }
                    });
                    items.push(item);
                }
                setData(items);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoadingData(false);
            })
    }

    useEffect(() => {
        fetchTariffs();
        return () => {
            setData([]);
        }
    }, [paginationData]);

    const formatRowData = (rowData) => {
        let formatted = [];
        rowData.map((d, idx) => {
            if (d.key === "effectiveStart" || d.key === "effectiveEnd") {
                formatted.push({
                    ...d,
                    value: fDateTime(+d.value * 1000),
                });
            } else {
                formatted.push(d);
            }
        })
        return formatted;
    }

    const modalHandler = (modalType, index = undefined) => {
        if (modalType === "add") {
            setAddTariffModalStatus(!openAddTariffModal);
        } else if (modalType === "edit") {
            setEditModalStatus(!openEditModal);
        } else if (modalType === "delete") {
            setDeleteModalStatus(!openDeleteModal);
        }

        if (index) { setSelectedRow(rowArrayToObject(data[index])) };
    }

    const getActionItems = (index) => {
        return (
            <Stack direction="row" spacing={2}>
                <Tooltip title={t('edit-tariff')}>
                    <IconButton color="secondary" size="small" aria-label="edit-sms-tariff" onClick={() => modalHandler("edit", index)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={t('delete-tariff')}>
                    <IconButton color="secondary" size="small" aria-label="delete-sms-tariff" onClick={() => modalHandler("delete", index)}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Stack>
        )
    }

    return (
        <>
            <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus} />
            <BaseModal title={t('add-new-tariff')} open={openAddTariffModal} setOpen={setAddTariffModalStatus} children={<TFATariffForm successCallback={fetchTariffs} formType="add" formData={{}} setModalStatus={setAddTariffModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseModal title={t('edit-tariff-0')} open={openEditModal} setOpen={setEditModalStatus} children={<TFATariffForm formType="edit" successCallback={fetchTariffs} formData={selectedRow} setModalStatus={setEditModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseModal title={t('delete-tariff-0')} open={openDeleteModal} setOpen={setDeleteModalStatus} children={<DeleteTariffForm tariffType="2fa" successCallback={fetchTariffs} formData={selectedRow} setModalStatus={setDeleteModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <AddNewButton label={t('add-new-tariff-0')} onClick={() => modalHandler("add")} />
            <br />
            <BaseTable
                head={TABLE_HEAD}
                data={[...data].map((d, idx) => { return formatRowData(d); })}
                actionItemPrep={getActionItems}
                pagination={{ paginationData: { ...paginationData, totalCount: totalCount }, setPaginationData: setPaginationData }}
                loadingData={loadingData}
            />
        </>
    );
}
